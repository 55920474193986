<template>
    <div class="menu-contenedor">
        <div class="logos">
            <div class="logo-1">
                <img src="../assets/img/logo-fge.png" alt="">
            </div>
            <div class="logo-2">
                <img src="../assets/img/logoNuevo.png" alt="">
            </div>
        </div>
        <div class="menu-items">
            <ul>
                <router-link to="/"><li>Inicio</li></router-link>
                <router-link to="consulta"><li>Consulta</li></router-link> 
                <router-link to="informacion"><li>Información</li></router-link>
                <router-link to="contacto"><li>Contacto</li></router-link>
            </ul>
        </div>
    </div>
  
</template>

<script>
export default {
    name: "Menu",
}
</script>

<style scoped>
    .menu-contenedor{
        width:70%;
        height:90px;
        margin:0 auto;
        background-color:#fff;
        display:flex; 
    }
    .logos{
        display:flex; align-items:center; justify-content:center;
        width:30%;
        height: 100%;
    }
    .logo-1{
        width:45%;
        height:100%;
        margin:0 auto;
        display:flex; align-items:center; justify-content:center;
    }
    .logo-1 img{
        width:100%;
    }
    .logo-2{
        width:45%;
        height:100%;
        margin:0 auto;
        display:flex; align-items:center; justify-content:center;
    }
    .logo-2 img{
        width:100%;
    }
    .menu-items{
        width:70%;
        height:100%;
        margin:0 auto;
    }
    ul{
        width:100%;
        height:100%;
        margin:0;
        display:flex; align-items:center; justify-content: space-evenly;
        padding:0;
        list-style: none;
        cursor:pointer;

    }
    li{
        font-size:12px;
    }
    li:hover{
        border-bottom:2px solid red;
    }
</style>