<template>

<div class="contenedor-principal">
     <div class="layout-menu">
       <Menu/>
    </div>
    <router-view/>
    <div class="layout-contenedor">
        <slot/>
    </div>
    <div class="layout-footer">
        <Footer/>
    </div>
</div>
   
  
</template>

<script>
import Menu from '../components/Menu.vue'
import Footer from '../components/Footer.vue'
export default {
    name:"BasicLayout",
    components:{
        Menu,
        Footer
    }
}
</script>

<style>

    .contenedor-principal{
        width:100%;
    }

    .layout-menu{ 
        width:100%;
        background-color:#fff;
    }

    .layout-contenedor{

        width:100%;
        
    }

    .layout-footer{
        width:100%;
       
        
    }

</style>