<template>
  <div class="footer-datafem">
      <div class="footer-titulo">
          <h3>Plataforma de Datos de Feminicidios</h3>
          <p>En el Estado de Morelos Periodo 2000 - 2020</p>
      </div>
      <div class="footer-item">
          <h3>Fiscalía General del Estado de Morelos</h3>
          <p><a href="https://fiscaliamorelos.gob.mx/" target="_blank" rel="noopener noreferrer">
          Sitio Web</a> | <a href="https://www.facebook.com/FiscaliaGeneralMorelos/" target="_blank" rel="noopener noreferrer">Facebook</a> | <a href="https://twitter.com/Fiscalia_Mor" target="_blank" rel="noopener noreferrer">Twitter</a> | <a href="https://www.youtube.com/channel/UC7P3wIErXShky_c2J1UNitA" target="_blank" rel="noopener noreferrer">Youtube</a></p>
          <p>Telefóno: (777) 3 29 15 00</p>
      </div>
      <div class="footer-item">
          <h3>Fiscalía Especializada para la Investigación y Persecución del Delito de Feminicidios</h3>
          <p><a href="https://fiscaliamorelos.gob.mx/" target="_blank" rel="noopener noreferrer">
          Sitio Web</a> | <a href="https://www.facebook.com/FiscaliaGeneralMorelos/" target="_blank" rel="noopener noreferrer">Facebook</a> | <a href="https://twitter.com/Fiscalia_Mor" target="_blank" rel="noopener noreferrer">Twitter</a> | <a href="https://www.youtube.com/channel/UC7P3wIErXShky_c2J1UNitA" target="_blank" rel="noopener noreferrer">Youtube</a></p>
          <p>Teléfono: (777) 120 5501 y (777) 170 55 01 </p>
      </div>
      <div class="footer-item">
            <h3>Instituto de la Mujer para el Estado de Morelos</h3>
            <p><a href="https://www.immorelos.org.mx/" target="_blank" rel="noopener noreferrer">
            Sitio Web</a> | <a href="https://www.facebook.com/IMMORELOS/" target="_blank" rel="noopener noreferrer">Facebook</a> | <a href="https://twitter.com/IMMORELOS" target="_blank" rel="noopener noreferrer">Twitter</a> | <a href="https://www.youtube.com/channel/UCms2UXxn65e_yDmPkiNIlgA/featured" target="_blank" rel="noopener noreferrer">Youtube</a></p>
            <p>Telefóno: (777) 173 00 66</p>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
    .footer-datafem{
        width:100%;
        background-color: #525056;
        padding-top:20px;
        margin-top:100px;
        display: flex; flex-wrap:wrap;
    }
    .footer-titulo{
        width:100%;
        text-align:center;
        color: #fff;
    }
    .footer-titulo h3, p{
        margin:0;
    }

    .footer-item{
        width:33%;
        text-align:center;
        margin:20px auto;
        color: #fff;
    }
    .footer-item a {
        color:#fff;
    }

</style>