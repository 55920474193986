<template>

     <div>
        <svg v-if="nombre==='boardIcon'" class="color2 icono" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 32 40" enable-background="new 0 0 32 32" xml:space="preserve"><g><path  d="M26,3.5h-4.5V3.059c0-0.276-0.224-0.5-0.5-0.5h-3.06C17.324,1.615,17.063,1.5,16,1.5s-1.324,0.115-1.94,1.059H11   c-0.276,0-0.5,0.224-0.5,0.5V3.5H6C5.724,3.5,5.5,3.724,5.5,4v26c0,0.276,0.224,0.5,0.5,0.5h20c0.276,0,0.5-0.224,0.5-0.5V4   C26.5,3.724,26.276,3.5,26,3.5z M11,7.5h10c0.276,0,0.5-0.224,0.5-0.5V6.5h1v20H9.438v-20H10.5V7C10.5,7.276,10.724,7.5,11,7.5z    M11.5,3.559h2.834c0.172,0,0.332-0.088,0.423-0.234C15.276,2.5,15.276,2.5,16,2.5s0.724,0,1.243,0.825   c0.092,0.146,0.251,0.234,0.423,0.234H20.5V6.5h-9V3.559z M25.5,29.5h-19v-25h4v1H8.938c-0.276,0-0.5,0.224-0.5,0.5v21   c0,0.276,0.224,0.5,0.5,0.5H23c0.276,0,0.5-0.224,0.5-0.5V6c0-0.276-0.224-0.5-0.5-0.5h-1.5v-1h4V29.5z"/><path d="M12,11.5h8c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5h-8c-0.276,0-0.5,0.224-0.5,0.5S11.724,11.5,12,11.5z"/><path d="M12,14.5h8c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5h-8c-0.276,0-0.5,0.224-0.5,0.5S11.724,14.5,12,14.5z"/><path d="M12,17.5h8c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5h-8c-0.276,0-0.5,0.224-0.5,0.5S11.724,17.5,12,17.5z"/><path d="M12,20.5h8c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5h-8c-0.276,0-0.5,0.224-0.5,0.5S11.724,20.5,12,20.5z"/><path d="M12,23.5h8c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5h-8c-0.276,0-0.5,0.224-0.5,0.5S11.724,23.5,12,23.5z"/></g></svg>

        
        <svg v-else-if="nombre==='descargaIcon'" class="color2 icono" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 32 40" enable-background="new 0 0 32 32" xml:space="preserve"><g><g><g><path d="M16,2c7.72,0,14,6.28,14,14s-6.28,14-14,14S2,23.72,2,16S8.28,2,16,2 M16,0C7.16,0,0,7.16,0,16s7.16,16,16,16     s16-7.16,16-16S24.84,0,16,0L16,0z"/></g></g></g><g display="none"><g display="inline"><g><polyline fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" points="19.64,15.17 16,19.77 12.36,15.17         "/><line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="16" y1="19.8" x2="16" y2="7.01"/></g><line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="23" y1="24.49" x2="9" y2="24.49"/></g></g><g><g><g><g><polygon points="16,21.38 11.57,15.79 13.14,14.55 16,18.16 18.86,14.55 20.43,15.79     "/></g><g><rect x="15" y="7.01" width="2" height="12.8"/></g></g><g><rect x="9" y="23.49" width="14" height="2"/></g></g></g></svg>
        
        <svg v-else-if="nombre==='chartIcon'" class="color2 icono" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 80" x="0px" y="0px"><rect class="color1" x="36" y="12" width="8" height="44"/><rect class="color2" x="20" y="20" width="8" height="36"/><rect class="color2" x="52" y="4" width="8" height="52"/><rect class="color1" x="4" y="28" width="8" height="28"/><path d="M61,62H3a1,1,0,0,1,0-2H61a1,1,0,0,1,0,2Z"/></svg>
        
        <svg v-else-if="nombre==='mapaMorelos'"  class="color2 icono" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 1200 1200" style="enable-background:new 0 0 1200 1200;" xml:space="preserve">
                                    <path 
                                    d="M509.88,761.69c3.36,0.31,6.21,0.31,8.9,0.97c0.88,0.22,2,2.22,1.88,3.27
                                        c-0.68,5.93-1.82,11.8-2.55,17.72c-0.24,1.97-0.08,4.14,0.53,6.02c1.23,3.78,2.73,7.5,4.41,11.1c1.28,2.75,0.51,4.53-0.75,7.3
                                        c-2.97,6.52-5.89,13.56-6.4,20.57c-0.7,9.59-8.52,20.67-17.65,23.16c-5.18,1.41-6.54-3.34-9.45-4.37c-2.58,1.48-5.02,2.41-6.81,4.05
                                        c-1.9,1.74-4.29,4.04-4.51,6.29c-0.59,6.02-3.79,6.37-8.68,5.18c-0.32-1.37-0.65-2.79-1-4.32c-1.8-0.29-4.77-1.08-4.91-0.74
                                        c-2.32,5.8-8.19,6.71-12.53,9.74c-2.96,2.07-5.15,5.22-7.86,7.68c-5.17,4.7-10.76,8.97-15.66,13.93c-5.71,5.77-4.79,9.52,2.18,13.81
                                        c2.03,1.25,4.01,3.14,5.11,5.22c2.55,4.81,6.65,6.25,12.01,4.44c3.51-1.19,7.38-1.76,11.09-1.79c7.54-0.06,15.29,3.33,22.33-2.81
                                        c1.34-1.17,5.77-0.4,7.64,0.99c3.08,2.3,0.87,5.39-1.09,7.46c-3.19,3.35-6.92,6.19-10.15,9.5c-3.31,3.39-5.71,8.04-9.57,10.45
                                        c-6.22,3.87-5.76,9.31-5.65,15.04c0.05,2.54-0.16,5.16,0.38,7.61c0.51,2.33,1.25,5.23,2.94,6.55c3.9,3.07,8.52,5.21,12.67,7.99
                                        c4.23,2.83,4.53,2.68,5.67-2.31c1.93-8.51,4.38-16.65,13.82-20.28c2.11-0.81,3.59-3.36,5.27-5.2c1.24-1.36,2.08-3.2,3.52-4.25
                                        c9.14-6.7,19.55-10.12,30.77-10.97c1.4-0.11,3.13,1.12,4.36,2.15c6.87,5.7,7.4,13.29,5.82,21.28c-1.24,6.22,1.86,11.76,7.19,12.22
                                        c6.09,0.52,8.57,4.64,5.61,10.09c-1.26,2.32-3.74,3.97-6.66,6.94c3.14,2.13,6.11,4.8,9.58,6.38c8.52,3.9,16.1,8.11,18.37,18.5
                                        c1.24,5.66,5.83,8.69,10.41,7.7c4.88-1.05,7.35,1.4,9.64,4.76c1.81,2.66,3.28,5.58,5.24,8.12c1.17,1.53,2.8,3.24,4.54,3.7
                                        c6.51,1.74,7.58,3.49,5.06,9.79c-1.01,2.52-2,5.04-3.36,8.47c6.61-1.57,11.27-1.4,13.18,4.92c0.18,0.58,1.42,0.79,2.08,1.29
                                        c5.86,4.39,5.57,11.8-0.3,15.98c-1.24,0.89-2.37,3.49-1.98,4.85c1.8,6.38,1.16,11.6-4.69,15.72c-1.63,1.14-3.31,3.4-0.06,5.24
                                        c2.62,1.48,5.05,3.32,7.75,4.63c1.16,0.57,3.2,0.83,4.11,0.19c3.13-2.2,5.51-4.93,4.52-9.37c-1.72-7.73,0.16-10.74,7.65-11.52
                                        c3.79-0.4,5.71-1.94,6.86-5.48c0.6-1.86,2.1-3.74,3.71-4.9c3.75-2.72,8.27-4.5,11.63-7.59c5.1-4.7,9.6-10.07,14.07-15.39
                                        c1.31-1.57,1.84-3.9,2.4-5.97c1.25-4.61,3.98-7.31,8.86-7.79c7.03-0.69,12.8-3.64,17.62-8.98c2.28-2.52,5.43-4.73,8.62-5.87
                                        c5.44-1.93,6.69-3.29,5.89-9.23c-0.6-4.51-1.54-9.07-1.35-13.57c0.12-2.9,1.34-7.42,3.25-8.17c2.43-0.95,6.59,0.46,9.06,2.19
                                        c3.72,2.6,7.44,2.98,10.89,1.11c4.06-2.21,7.49-2.12,11.56-0.33c1.63,0.72,4.24,0.32,5.95-0.5c2.89-1.38,5.2-4.46,8.14-5.12
                                        c6.52-1.47,13.08-2.29,18.21-7.24c0.88-0.85,2.5-0.98,3.81-1.31c6.59-1.64,7.05-2.19,5.87-9.13c-0.58-3.44-0.21-6.15,2.67-8.66
                                        c1.18-1.02,1.89-3.85,1.36-5.36c-2.27-6.56-1.27-12.01,5-16.45c-1.99-2.29-3.26-4.91-5.32-5.9c-8.85-4.28-15.7-10.89-21.54-18.44
                                        c-2.95-3.82-5.76-5.24-10.29-4.01c-5.04,1.36-9.33-0.28-13.06-3.77c-1.1-1.03-2.25-2.44-3.57-2.7c-5.27-1.02-7.77-4.41-8.76-9.29
                                        c-0.73-3.61-1.3-7.26-2.01-10.88c-1.16-5.91-2.23-11.84-3.69-17.68c-0.3-1.19-1.97-2.3-3.26-2.92c-2.12-1.02-4.46-1.59-6.69-2.39
                                        c-7.35-2.61-9.09-5.59-7.85-13.43c0.99-6.25-3.58-10.27-10-9.27c-1.82,0.28-5.17-0.51-5.61-1.65c-1.44-3.76-2.15-7.88-2.59-11.92
                                        c-0.58-5.29-0.78-10.63-0.86-15.95c-0.08-5.27-4.93-4.16-7.5-6.1c-2.7-2.04-5.35-6.67-7.54-6.39c-5.27,0.68-11.39,0.32-15.33,5.79
                                        c-3.72,5.16-15.28,6.15-17.69,1.57c-2.93-5.55-5.05-11.57-7.14-17.51c-0.96-2.74-1.61-4.19-5.18-3.8
                                        c-8.22,0.89-16.52,1.14-24.79,1.35c-1.31,0.03-3.47-1.23-3.87-2.37c-3.45-9.88-6.66-19.85-9.8-29.84c-2.4-7.64-4.57-15.34-6.9-23.01
                                        c-0.19-0.61-0.88-1.06-1.14-1.66c-1.19-2.72-2.67-5.37-3.36-8.22c-0.97-4.01-1.32-8.17-2.02-12.77c-1.73,0.88-2.58,1.38-3.48,1.74
                                        c-5.45,2.22-8.8,0.11-9.92-5.63c-0.46-2.31-2.01-4.48-3.37-6.52c-1.2-1.81-3.02-3.21-4.21-5.02c-6.41-9.71-10.98-10.96-20.38-4.15
                                        c-4.07,2.94-8.23,3.69-12.4,2.53c-7.58-2.12-15.04-4.83-22.29-7.91c-4.63-1.96-11.37-1.79-13.91,2.57
                                        c-2.64,4.51-4.6,9.76-5.41,14.92c-0.82,5.19-2.97,6.86-7.89,6.93c-5.34,0.07-11.4,8.21-10.14,13.25c0.35,1.41,1.84,2.58,2.02,3.97
                                        c1.26,9.47,6.56,11.1,14.93,8.62c3.85-1.14,5.84,1.34,5.86,5.49c0.01,2.66-0.22,6.59,1.31,7.74c5.3,3.98,6.65,9.38,7.54,15.27
                                        C508.97,760.56,509.71,761.39,509.88,761.69z"/>

                                    <path  
                                    d="M694.66,682.26c4.31,2.63,5.69,7.86,4.31,15.21c-0.39,2.09-0.71,4.49-0.06,6.41
                                        c1.65,4.88-0.61,7.4-4.6,9.24c-6.18,2.84-12.39,5.64-18.54,8.55c-0.79,0.38-1.52,1.28-1.87,2.12c-2.05,4.92-3.66,10.05-6.04,14.8
                                        c-3.54,7.08-7.68,13.85-11.36,20.85c-1.2,2.28-1.83,4.88-2.62,7.36c-0.68,2.16-0.53,5.18-1.96,6.39c-3.14,2.66-3.13,5.54-2.79,9.08
                                        c0.56,5.88,3.26,10.69,5.95,15.79c1.75,3.33,3.09,6.34,6.93,5.78c3.07-0.45,6-2.25,8.85-3.72c2.4-1.24,4.43-3.67,6.91-4.19
                                        c6.2-1.3,8.53-4.02,7.81-10.66c-0.87-8.09,6.83-18.59,14.82-20.06c1.27-0.23,2.65,0.18,3.99,0.22c5.36,0.13,10.73,0.28,16.09,0.31
                                        c3.68,0.02,7.36-0.37,11.03-0.2c5.56,0.26,9.49-4.93,7.52-10.13c-0.72-1.89-1-3.95-1.78-5.81c-3.07-7.36-4.26-15.62-10.44-21.51
                                        c-3.43-3.27-3.58-7.44-2.75-11.82c1.27-6.71,5.8-11.19,10.26-15.86c4-4.19,7.96-8.55,11.11-13.37c1.46-2.24,1.9-4.32,5.21-4.15
                                        c3.42,0.18,4.64,2.37,4.63,4.95c-0.03,5.12,2.28,8.95,5.17,12.89c1.85,2.52,3.31,5.72,3.77,8.79c0.98,6.61,3.6,12.23,7.68,17.42
                                        c2.37,3.01,3.74,6.17,0.97,10.16c-1.85,2.67-1.09,5.28,3.1,5.23c3.39-0.04,6.8,0.25,10.18,0.07c1.52-0.08,3-0.95,4.49-1.46
                                        c-0.67-1.47-1.25-2.99-2.02-4.41c-3.22-5.97-6.53-11.9-9.73-17.88c-0.38-0.7-0.27-1.65-0.45-2.48c-0.74-3.36-0.93-6.99-2.37-10.01
                                        c-4.28-8.97-8.57-18-13.76-26.46c-5.4-8.81-1.21-10.89,5.69-14.76c1.03-0.58,2.14-1.08,3.28-1.38c2.89-0.76,6.04-0.92,6.52-5.04
                                        c0.07-0.58,2.9-1.65,3.72-1.18c5.53,3.11,10.72,2.28,15.92-0.63c0.27-0.15,0.7-0.02,1-0.15c7.44-3.26,14.93-0.16,22.38-0.14
                                        c6.98,0.02,14.23-1.47,20.51,3.19c0.13,0.09,0.57-0.24,1.21-0.53c-0.11-1.02,0.07-2.31-0.41-3.27c-2.51-5.09,0.3-7.95,4.16-10.41
                                        c1.14-0.73,2.33-1.39,3.5-2.07c4.84-2.8,4.65-8.94,0.85-13.01c-2.91-3.11-6.01-7.75-5.9-11.62c0.24-9.11,2.35-18.17,3.83-28.09
                                        c4.77,1.43,8.97,2.48,13.01,3.97c2.4,0.89,5.18,4,6.64,3.39c2.21-0.93,3.42-4.24,5.04-6.54c0.49-0.69,0.98-1.39,1.39-2.13
                                        c4.06-7.37,7.95-14.84,12.27-22.06c1.14-1.9,3.54-3.08,5.44-4.49c1.14-0.85,3.36-1.4,3.46-2.28c0.68-5.86,0.93-11.77,1.33-17.92
                                        c-7.13,1.67-12.81,4.05-18.48,4.03c-5.25-0.02-11.14,3.32-16.03-1.45c-3.53-3.44-8.58-6.26-10.28-10.44
                                        c-2.6-6.39-8.24-7.39-12.92-9.1c-4.42-1.62-6.53-2.92-6.46-7.82c0.03-2.32-1.88-4.67-3.16-7.59c-2.38,2.82-3.64,4.63-5.21,6.11
                                        c-3.1,2.93-6.37,5.67-9.55,8.51c-1.26,1.12-2.25,2.81-3.7,3.4c-5.22,2.11-7.57,6.54-9.84,11.14c-1.28,2.58-2.32,5.35-4,7.64
                                        c-1.51,2.06-3.74,3.59-5.65,5.35c-1.49-1.96-3.17-3.82-4.43-5.92c-1.64-2.75-2.23-6.71-4.55-8.34c-5.06-3.58-6.86-7.96-7.14-13.85
                                        c-0.07-1.55-3-4.19-4.68-4.24c-12.07-0.36-18.76-5.2-22.61-16.16c-0.75-2.13-3.78-4.77-5.87-4.87c-5.36-0.25-10.74-1.33-16.28,1.23
                                        c-3.21,1.48-8.06,0.94-11.64-0.27c-5.87-1.99-11.29-4.22-17.63-1.79c-3.52,1.35-6.94,0.83-8.93-3.34c-0.49-1.03-2.32-1.51-3.62-2.04
                                        c-1.81-0.75-3.82-1.08-5.52-1.99c-4.94-2.66-7.46-9.35-14.48-8.63c-0.44,0.05-1.06-0.76-1.45-1.28
                                        c-6.89-9.18-17.05-12.96-26.65-16.78c-0.35,7.42-0.82,14.62-0.95,21.82c-0.03,1.6,0.62,3.9,1.77,4.69c2.62,1.8,2.85,3.87,1.41,6.07
                                        c-2.67,4.07-5.61,7.98-8.6,11.84c-2.83,3.64-7.3,6.73-8.42,10.78c-1.32,4.81-3.62,8.13-7.01,11.27c-1.71,1.58-4.09,3.27-4.5,5.26
                                        c-1.25,6.12-1.66,12.41-2.46,18.63c-0.66,5.14-1.5,10.27-2.06,15.42c-0.33,3.04-0.7,6.18-0.33,9.18c0.61,4.95,1.77,9.83,2.75,14.73
                                        c1.01,5.05,0.96,10.26,5.54,14.3c1.71,1.51,1.62,5.65,1.33,8.48c-0.08,0.78-3.82,1.19-5.88,1.78c-1.3,0.37-2.59,0.76-3.9,1.11
                                        c-4.2,1.11-8.48,1.97-12.56,3.42c-1.71,0.61-3.7,2.19-4.31,3.8c-3.31,8.79,3.99,15.07,5.85,22.68c0.11,0.45,1.05,0.83,1.68,1
                                        c4.04,1.13,3.71,3.83,2.45,6.8c-3.03,7.18-6.1,14.34-9.74,22.88c8-1.25,14.89-2.22,21.73-3.43c3.93-0.69,7.85-1.57,11.64-2.79
                                        c1.14-0.37,2.68-2.29,2.55-3.32c-0.68-5.75,2.5-6.04,6.52-4.79c2.16,0.67,4.03,2.22,6.1,3.23c1.72,0.84,3.48,1.81,5.33,2.11
                                        c2.24,0.37,4.58,0.09,7.34,0.09c0.78-0.67,1.92-2.44,3.34-2.7c3.66-0.67,4.83-2.76,5.56-6.14c1.14-5.32,2.11-5.04,6.65-1.88
                                        c6.4,4.45,12.65,9.47,20.81,10.67C691.81,671.85,695.24,676.47,694.66,682.26z"/>
                                    <path   
                                    d="M883.58,649.07c-2.75,3.96-4.44,6.64-6.38,9.11c-0.97,1.23-2.57,3.13-3.58,2.95
                                        c-5.7-0.98-11.09,0.28-16.55,1.42c-0.92,0.19-1.99-0.49-2.98-0.45c-8.41,0.26-16.5-1.37-24.69-3.13
                                        c-5.36-1.15-11.35,0.52-16.68-0.72c-6.63-1.55-12.5,0.06-18.11,2.45c-3.76,1.61-6.59,2.9-10.19,0.05c-2.23-1.77-4.21-1.36-5.49,1.77
                                        c-0.61,1.49-2.33,2.89-3.88,3.57c-3.58,1.58-7.48,2.47-10.98,4.17c-0.94,0.46-1.66,3.13-1.22,4.32c1.65,4.42,3.41,8.9,5.88,12.89
                                        c5.84,9.42,12.54,18.39,12.53,30.22c0,0.78,0.51,1.62,0.93,2.34c3.33,5.68,6.88,11.25,9.95,17.07c0.89,1.69,1,4.33,0.33,6.1
                                        c-0.41,1.08-2.98,1.76-4.62,1.86c-3.72,0.22-7.46,0.03-11.19-0.1c-7.09-0.25-9.03-2.78-6.05-9.09c1.56-3.3,0.79-5.64-0.94-7.94
                                        c-4.04-5.36-7.28-10.93-7.9-17.83c-0.21-2.28-1.11-4.76-2.42-6.63c-3.35-4.76-6.7-9.36-6.7-15.57c0-0.8-1.35-1.6-2.08-2.41
                                        c-0.77,0.73-1.71,1.35-2.27,2.22c-1.15,1.79-1.78,3.98-3.17,5.51c-5.12,5.62-10.75,10.79-15.62,16.6
                                        c-4.38,5.23-5.08,12.56-1.42,15.84c4.06,3.63,5.94,7.97,7.45,12.9c1.66,5.44,3.72,10.77,5.83,16.06c2.7,6.73-2.93,14.02-10.27,13.41
                                        c-3.49-0.29-7.03,0.19-10.55,0.22c-4.86,0.05-9.81,0.6-14.56-0.13c-8.71-1.33-17.88,5.76-17.91,14.52c-0.01,3.36,0,6.72,0,10
                                        c5.99,1.78,7.28,10.49,14.9,9.45c0.38-0.05,1.16,2.24,1.62,3.48c2.63,7.05,2.41,14.25,1.25,21.55c-0.85,5.34,2.35,9.49,6.37,8.58
                                        c6.69-1.51,13.24,4.45,12.41,11.29c-1.09,8.98-0.35,9.64,8.15,12.55c3.45,1.18,8.04,3.37,9.12,6.22c2.39,6.32,2.96,13.35,4.13,20.11
                                        c1.16,6.71,0.75,14.28,9.87,16.12c0.61,0.12,1.26,0.63,1.64,1.15c3.99,5.47,9.45,6.13,15.48,4.69c4.09-0.97,6.75,0.6,8.91,4.05
                                        c1.6,2.55,3.47,5.12,5.77,7.01c7.44,6.13,15.14,11.95,23.05,18.13c0.36-0.19,1.24-0.67,2.12-1.15c8.13-4.49,15.84-10.27,24.51-13.07
                                        c7.68-2.48,15.74-3.6,22.65-8.23c0.8-0.54,1.91-0.69,2.91-0.84c4.91-0.71,9.83-1.87,14.74-1.9c5.68-0.03,7.8-3.24,8.65-7.89
                                        c0.72-3.89,2.16-6.63,5.96-8.69c2.47-1.34,5.86-4.21,1.62-8.06c-1.09-0.99-1.45-3.46-1.23-5.13c0.85-6.38,2.15-12.7,3.11-19.07
                                        c0.46-3.03,1.97-4.39,5.01-4.62c4.23-0.32,8.46-0.77,12.64-1.46c8.31-1.38,10.49-5.41,8.57-13.44c-1.11-4.65-1.16-9.59-1.35-14.41
                                        c-0.21-5.11,1.71-10.31-1.46-15.3c-0.65-1.02,0.45-4.64,1.43-4.95c5.11-1.65,6.14-5.67,7.48-10.03c0.53-1.73,3.03-4.17,4.48-4.07
                                        c5.51,0.39,7.59-3.08,7.02-7.03c-1.07-7.42,3.59-8.78,8.71-9.45c3.53-0.46,7.33-0.07,10.83,0.7c9.14,2.03,17.21,0.28,24.87-5.51
                                        c-0.92-1.36-1.39-2.5-2.22-3.24c-6.38-5.73-13.02-11.12-17.46-18.77c-1.12-1.93-4.27-3.28-6.7-3.72
                                        c-8.23-1.49-15.29-5.45-22.36-9.55c-8.44-4.89-14.7-13-24.22-16.29c-3.18-1.1-6.23-2.6-9.44-3.63c-2.56-0.82-5.3-1.07-7.88-1.84
                                        c-1.67-0.5-4.6-1.61-4.54-2.22c0.3-3.27,0.51-8.51,2.34-9.29c4.04-1.7,6.85-7.23,12.66-4.77c1.77,0.75,5.72,0.52,6.11-0.38
                                        c1.16-2.66,1.62-5.95,1.28-8.86c-0.39-3.26-2.07-6.34-2.98-9.56c-0.7-2.47-0.96-4.59-4.43-5.23c-1.86-0.34-3.39-3.19-4.81-5.1
                                        c-1.72-2.31-3.12-4.84-4.73-7.23c-0.94-1.38-2.04-2.65-3.05-3.99C889.18,656.6,886.82,653.41,883.58,649.07z"/>
                                    <path   
                                    d="M253.77,869.62c-0.73,6.44,1.63,10.02,7.55,12.77c2.92,1.35,6.13,2.18,8.86,3.83
                                        c5.45,3.29,5.28,7.55,0.17,11.14c-1.41,0.99-2.55,2.85-3.06,4.54c-1.84,6.09-0.4,11.21,4.82,15.29c4.84,3.79,6.57,8.79,4.96,14.94
                                        c-0.37,1.41,0.31,3.09,0.51,4.68c4.58-0.55,8.43-1.01,11.52-1.38c-2.27-4.91-4.59-9.25-6.24-13.84c-0.7-1.96,0.01-4.43,0.08-6.67
                                        c2,0.24,4.16,0.09,5.97,0.81c3.9,1.55,6.98,2.63,9.98-2.06c1.38-2.15,5.11-3,7.94-3.96c3.42-1.16,5.48,0.2,5.9,4.02
                                        c0.41,3.69,1.09,7.35,1.77,11c0.8,4.26,2.47,8.49,2.45,12.73c-0.06,9.49-0.39,9.51,9.05,11.17c2.25,0.4,5.26,1.72,6.66,0.77
                                        c7.81-5.29,15.95-4.55,24.43-2.65c3.09,0.69,6.86,1.85,9.4,0.72c5.14-2.3,8.25-0.06,11.93,2.69c2.01,1.5,4.64,2.56,7.13,2.93
                                        c6.15,0.91,12.39,1.25,18.58,1.95c2.53,0.29,4.81,0.65,5.26-2.86c0.49-3.81,2.84-4.74,6.49-4.63c12.21,0.36,24.44,0.32,36.65,0.62
                                        c3.75,0.09,6.09-0.32,6.02-5.06c-0.03-2.03,1.86-4.29,3.32-6.08c1.99-2.44,4.37-4.57,6.64-6.77c5.05-4.9,10.22-9.67,15.12-14.72
                                        c1.11-1.14,1.31-3.18,1.93-4.8c-1.57-0.18-3.15-0.57-4.7-0.47c-0.91,0.06-1.71,1.03-2.65,1.35c-2.93,0.98-5.87,2.4-8.87,2.59
                                        c-3.85,0.25-7.77-0.52-11.65-0.85c-3.83-0.32-7.39-0.8-11.2,1.49c-4.2,2.53-11.87,0.64-13.12-2.6c-1.49-3.85-4.08-6.11-7.36-8.51
                                        c-5.81-4.27-7.26-5.29-3.62-11.9c4.01-7.27,9.49-12.92,16.46-17.12c9.2-5.54,9.76-6.48,7.53-16.62c-0.11-0.5-0.29-1.01-0.24-1.5
                                        c0.73-7.33-6.3-8.58-9.85-12.54c-1.57-1.75-3.72-3.03-5.03-4.92c-1.17-1.69-1.54-3.93-2.21-5.77c-9.41,1.7-15.15,7.04-18.83,15.14
                                        c-0.68,1.5-2.82,3.17-4.4,3.3c-5.33,0.42-10.07,1.26-14.2-5.18c-3.88-6.05-11.38-9.73-17.03-14.74c-1.31-1.16-2.36-3.57-2.19-5.28
                                        c0.83-8.32-4.95-14.02-13.11-12.47c-7.49,1.42-9.76-1.08-8.44-8.65c0.32-1.85-0.11-4.63-1.33-5.8c-2.62-2.51-1.86-4.45-0.64-7.18
                                        c1.75-3.94,2.96-8.13,4.19-12.28c0.19-0.63-0.65-2.19-1.24-2.33c-4.26-0.98-5.52-7.23-10.95-6.2c-0.39,0.07-1.69-1.61-1.56-2.28
                                        c1.29-6.72,2.44-13.51,4.37-20.07c1.21-4.12,3.8-7.82,5.57-11.79c2.17-4.9,3.81-9.6,9.35-12.57c4.23-2.27,7.64-6.83,10.32-11.06
                                        c3.47-5.49,17.65-8.95,22.6-4.79c6.98,5.86,14.35,9.62,23.47,4.45c0.81-0.46,1.98-0.46,2.97-0.42c8.04,0.32,9.3-1.09,9.87-9.15
                                        c0.23-3.2,2.17-6.25,2.62-9.46c0.46-3.3,1.33-7.55-0.22-9.92c-3.62-5.53-2.83-10.41-0.6-15.85c1.31-3.2,2.1-6.62,3.12-9.94
                                        c1.05-3.41,1.84-6.99,6.51-7.23c5.07-0.25,10.17-0.45,15.17-1.18c1.43-0.21,3.48-2.05,3.72-3.42c1.22-6.79,2.3-13.66,2.7-20.54
                                        c0.33-5.72-1.46-10.93-8.1-14.76c-0.39,3.27-0.79,5.77-0.96,8.28c-0.15,2.24,0.92,5.76-2.52,5.43c-3.14-0.3-7.51-1.77-8.84-4.13
                                        c-2.52-4.47-3.24-9.96-4.99-15.98c-2.86,1.7-5.85,3.73-9.07,5.29c-1.95,0.95-4.49,2.04-6.37,1.54c-5.2-1.37-10.27-3.35-15.24-5.45
                                        c-2.88-1.21-4.87-1.28-7.09,1.37c-3.05,3.65-9.68,4.95-13.62,2.74c-5.87-3.29-11.53-6.94-16.72-10.09
                                        c-0.34,3.55-1.15,8.14-1.11,12.72c0.03,3.86-1,7.9,2.42,11.48c1.82,1.9,1.64,5.09-2.25,6.26c-1.28,0.39-2.34,1.92-3.24,3.12
                                        c-1.32,1.78-2.02,4.6-3.7,5.32c-1.22,0.52-4.11-1.21-5.32-2.7c-1.85-2.28-3.76-2.9-6.13-1.69c-2.94,1.5-5.66,3.4-8.53,5.04
                                        c-3.89,2.23-8.16,3.94-11.7,6.63c-7.88,5.99-14.55,6.38-22.86,0.74c-0.91-0.62-2.56-1.27-3.27-0.86
                                        c-6.15,3.53-12.31,7.07-18.12,11.11c-2.2,1.53-3.74,4.23-5.08,6.67c-0.71,1.3-1.1,4-0.36,4.67c4.23,3.83,4.09,7.76,1.99,10.57
                                        c3.01,6.58,5.86,12,7.89,17.71c0.8,2.23,0.46,5.45-0.58,7.64c-2.81,5.91-0.57,12.94,5.68,14.68c3.62,1.01,5.5,3.39,5.42,6.44
                                        c-0.14,5.51,1.12,10.27,4.39,14.68c0.73,0.99,0.85,2.6,0.87,3.93c0.11,6.62,0.55,13.27,0.01,19.84c-0.59,7.15-0.2,13.61,5.69,18.68
                                        c0.76,0.66,1.09,2.07,1.2,3.17c0.29,2.82,0.43,5.66,0.47,8.5c0.07,4.84,0.02,9.68,0.02,14.93c-7.38,0.03-14.53-6.08-21.02,1.87
                                        c-2.31,2.82-7.54,3.31-11.48,4.72c-3.03,1.08-3.42,2.72-1.84,5.42c2.21,3.79,5.3,3.87,8.02,1.39c3.76-3.44,8.28-3.06,11.08-0.67
                                        c1.89,1.62,1.65,6.47,1.32,9.77c-0.7,6.85-2.15,13.63-3.15,20.45c-0.19,1.28,0.43,2.66,0.46,4c0.06,3.07,0.68,6.36-0.19,9.17
                                        c-1.28,4.13-4.01,7.8-5.46,11.89c-1.65,4.66-2.78,5.7-7.16,3.32c-4.2-2.28-8.44-2.54-13.06-2.94c-3.98-0.34-7.77-3.34-11.55-5.34
                                        C256.05,872.49,255.34,871.28,253.77,869.62z"/>
                                    <path  
                                    d="M525.91,134.93c-0.6,2.18-0.54,4.89-1.87,6.26c-4.35,4.49-4.84,10.27-6.39,15.77
                                        c-0.82,2.91-2.38,5.6-3.56,8.41c-1.87,4.44-3.91,8.82-5.52,13.36c-3,8.44-5.73,16.97-8.6,25.45c-0.1,0.31-0.4,0.54-0.55,0.84
                                        c-1.44,2.75-3.63,5.38-4.11,8.29c-0.75,4.57-3.24,6.79-7.2,8.19c-3.49,1.23-8.79,1.66-10.01,4.1c-2.66,5.27-7.81,7.28-11.28,11.28
                                        c-4.81,5.54-5,5.36,0.7,10.3c0.62,0.54,1.27,1.22,1.53,1.97c2.13,6.19,5.47,12.32,5.94,18.65c0.71,9.48-0.58,19.12-1.08,28.68
                                        c-0.15,2.87-0.81,5.74-0.69,8.58c0.23,5.31,0.93,10.59,1.28,15.89c0.14,2.19-0.6,4.52-0.09,6.57c0.46,1.84,1.71,3.99,3.26,4.94
                                        c6.24,3.84,12.96,6.92,19.01,11.01c2.56,1.73,3.88,5.29,5.83,7.97c1.14,1.56,2.18,3.32,3.66,4.48c3.72,2.91,7.54,5.74,11.55,8.22
                                        c4.79,2.97,9.8,5.59,14.78,8.25c0.65,0.35,1.67,0.05,2.51-0.01c7.71-0.48,15.42-1.22,22.64-4.17c10.17-4.15,21.6-6.6,25.5-19.28
                                        c1.11-3.62,4.21-3.79,7.92-3.49c11.18,0.89,21.66-1.04,30.01-9.57c1.17-1.2,3.27-1.93,5-2.05c6.75-0.49,13.52-0.82,20.28-0.88
                                        c6.82-0.06,12.27-1.82,14.84-8.94c1.1-3.04,2.92-5.26,7-3.7c1.28,0.49,3.24-0.83,5.23-1.42c-0.71-1.59-1.29-2.45-1.49-3.39
                                        c-0.88-4.2-2.02-8.39-2.34-12.64c-0.2-2.72,0.96-5.52,1.34-8.3c1.02-7.46,2.15-14.92,2.8-22.42c0.29-3.3,0.04-6.83-0.85-10.01
                                        c-1.07-3.77-3.87-7.14-4.5-10.91c-0.67-4.02-2.27-5.92-6.02-6.98c-2.32-0.65-5.15-1.73-6.39-3.54c-4.65-6.82-8.49-14.19-13.12-21.03
                                        c-4.79-7.07-10.24-13.68-15.21-20.62c-4.02-5.61-10.89-9.44-11.19-17.4c-0.14-3.73-2.25-4.55-5.57-5.02
                                        c-3.61-0.52-7.31-1.58-10.58-3.19c-11.42-5.61-22.69-11.52-33.99-17.39C552.97,149.13,539.63,142.11,525.91,134.93z"/>
                                    <path 
                                    d="M660.79,660.98c1.75,9.35-7.76,8.41-10.77,13.36c-0.26,0.42-2.11-0.08-3.21-0.25
                                        c-1.1-0.16-2.2-0.34-3.26-0.67c-2.72-0.87-5.46-1.73-8.11-2.78c-2.51-1-4.93-2.23-7.38-3.36c0.13,0.74,0.27,1.47,0.4,2.21
                                        c-1.79,2.24-3.14,5.46-5.46,6.52c-4.03,1.83-8.63,2.59-13.07,3.32c-6.63,1.1-13.32,1.85-20,2.63c-2.71,0.32-4.05-1.32-3.15-3.74
                                        c2.5-6.73,5.32-13.35,8.04-20.01c0.61-1.49,1.31-2.95,1.86-4.19c-1.98-1.41-4.34-2.18-4.76-3.55c-2.36-7.67-8.09-14.18-6.72-23.26
                                        c0.68-4.51,2.39-6.21,6.21-7.05c7.13-1.56,14.26-3.16,22.04-4.88c-0.73-2.38-0.71-4.92-1.96-5.99c-5.13-4.41-4.88-10.55-6-16.29
                                        c-0.86-4.42-2.05-8.8-2.51-13.25c-0.37-3.52-0.13-7.17,0.33-10.7c0.48-3.67,1.92-7.23,2.3-10.9c0.76-7.2,0.84-14.47,1.73-21.64
                                        c0.25-2.05,2.09-4.05,3.54-5.79c2.26-2.72,5.44-4.85,7.08-7.86c2.37-4.35,3.72-9.25,5.5-13.89c-1.1-1.55-2.72-3.82-4.74-6.66
                                        c-1.48,0.37-3.89,0.63-6,1.57c-3.75,1.68-5.82,0.09-7.27-3.12c-0.59-1.31-0.93-2.74-1.36-4.02c-4.56-0.02-7.75,7.82-12.83,1.13
                                        c-2.55,4.31-5.29,2.68-8.02,0.5c-1.7-1.36-3.21-3.45-5.13-3.96c-5.55-1.47-6.75-5.34-7.19-10.28c-0.21-2.35-0.9-5.13-2.4-6.8
                                        c-2.45-2.72-5.49-5.13-8.72-6.88c-5.39-2.93-10.39-6.89-17.58-2.88c-6.71,3.74-8.69,2.42-11.27-4.96c-1.47-4.21-3.98-5.47-7.68-4.56
                                        c-3.52,10.43-6.01,20.73-12.36,29.66c-4.49,6.33-3.05,16.61,1.41,22.87c2.7,3.8,4.82,8.15,6.48,12.52
                                        c2.13,5.6,3.42,11.52,5.29,17.23c1.35,4.13,1.93,7.63-3.44,9.29c-1.34,0.42-2.51,1.73-3.52,2.84c-4.88,5.34-9.04,11.4-16.19,14.25
                                        c-2.89,1.15-5.51,3.06-8.08,4.87c-1.03,0.72-2.03,2.01-2.28,3.21c-0.88,4.18-2.78,5.46-6.23,2.82c-5.1-3.91-11.53-7.2-10.99-15.32
                                        c0.02-0.37-0.51-0.78-0.78-1.17c-4.03,0.21-5.65,4.01-4.67,5.92c2.35,4.56-0.12,8.63,0.29,12.82c0.36,3.7,1.87,7.28,2.73,10.94
                                        c0.48,2.05,1.63,5.48,0.85,6.05c-4.51,3.27-2.6,7.6-2.61,11.58c-0.01,7.31,0.06,7.31-6.47,9.82c0.38,3.46,0.77,7.03,0.99,9.05
                                        c4.08,1.41,6.9,2.48,9.79,3.35c3.52,1.06,7.05,2.25,10.66,2.81c2.63,0.41,5.41-0.24,8.12-0.16c2.98,0.08,4.86,1.4,4.31,4.8
                                        c-1.37,8.47,1.86,12.74,10.42,13.39c4.36,0.33,4.45,2.27,3.25,5.8c-0.66,1.95-0.2,4.37,0.08,6.53c0.22,1.64,1.11,3.18,1.44,4.81
                                        c1.84,9.09,5.06,12.41,14.05,14.29c1.82,0.38,3.9,0.31,5.44,1.18c7.05,4.01,13.1,2.86,19.68-1.72c7.75-5.4,14.02-3.4,19.3,4.65
                                        c1.48,2.25,3.43,4.18,4.91,6.43c1.7,2.58,3.63,5.19,4.51,8.08c1.22,4.01,3.52,5.79,6.63,3.33c4.33-3.44,5.84-0.49,6.98,2.63
                                        c0.9,2.48,0.7,5.34,1.27,7.96c0.67,3.08,1.39,6.19,2.55,9.11c2.76,6.97,7.27,13.6,8.36,20.78c1.65,10.86,5.24,20.96,8.71,31.18
                                        c0.61,1.79,2.65,4.37,4.08,4.41c7.38,0.19,14.8-0.16,22.18-0.7c2.81-0.21,5.99-0.18,6.79-4.58c0.49-2.7,4.33-5.16,4.15-7.54
                                        c-0.52-6.98,4.07-11.63,6.63-17.08c3.24-6.88,9.09-12.78,11.2-19.88c2.13-7.17,4.19-12.42,12.08-14.41
                                        c4.05-1.02,7.57-3.97,11.5-5.66c3.29-1.42,4.96-3.01,3.66-6.94c-0.69-2.08-0.02-4.58-0.13-6.88c-0.22-4.31,3.01-9.65-3.63-12.16
                                        c-0.64-0.24-1.02-2-0.98-3.04c0.17-5.38-1.77-7.86-7.16-8.87c-1.97-0.37-4.17-0.44-5.84-1.39
                                        C672.96,668.62,667.47,665.08,660.79,660.98z"/>
                                    <path 
                                    d="M256.85,566.34c3.87-2.04,7.45-4.46,11.38-5.85c3.13-1.11,5.45,1.06,3.79,4.14
                                        c-2.13,3.97,0.15,5.79,2.22,8.34c3.26,4.01,6.15,8.32,9.24,12.48c0.76,1.03,1.57,2.65,2.54,2.8c6.97,1.06,9.04,5.9,10.22,11.93
                                        c0.25,1.25,2.29,2.79,3.71,3.06c4.68,0.88,6.1,4.44,7.49,8.11c0.93,2.45,1.32,5.14,2.49,7.45c2.87,5.68,0.37,13.58-5.2,16.37
                                        c-1.13,0.57-2.12,2.15-2.46,3.45c-0.99,3.77,2.29,4.83,4.22,7.15c4.33,5.2,9.94,3.99,13.83,2.52c6.69-2.53,12.38-7.61,18.72-11.21
                                        c3.6-2.04,7.56-3.93,11.56-4.56c1.84-0.29,4.14,2.67,6.31,4.04c0.75,0.47,1.72,0.58,3.58,1.15c-1.54-6.29,2.34-8.52,7.38-10.03
                                        c-5.91-5.78-4.1-11.96-3.67-18.15c0.49-7.09,0.11-14.24,0.11-20.38c-4.66-0.43-8.96-0.45-13.08-1.33
                                        c-3.63-0.78-7.06-2.53-10.58-3.84c-1.45-0.54-4.11-0.98-4.14-1.58c-0.1-1.86,0.58-3.86,1.36-5.63c0.95-2.19,2.13-4.34,3.51-6.29
                                        c2.29-3.22,4.24-7.22,7.41-9.13c3.56-2.15,8.4-2.09,12.49-3.53c1.67-0.58,3.86-2.45,4.05-3.95c0.56-4.47,0.42-9.06,0.16-13.58
                                        c-0.09-1.51-1.06-3.9-2.17-4.26c-5.59-1.85-8.19-6.52-9.45-11.24c-2.16-8.1-7.48-13.26-13.34-18.52
                                        c-5.11-4.59-10.1-9.57-13.97-15.18c-1.71-2.48-3.18-3.74-5.78-4.68c-4.09-1.49-8.53-2.76-11.9-5.33c-4.2-3.21-8.4-3.73-13.27-3.22
                                        c-3.35,0.36-6.81,0.65-10.12,0.21c-4.39-0.58-5.43-4.18-3.22-7.96c1.33-2.28,2.05-5.04,2.5-7.67c1.08-6.29,0.88-12.97,2.96-18.87
                                        c2.68-7.6,6.73-14.8,10.83-21.79c4.09-6.97,1.8-13.29-0.45-19.93c-2.53-7.47-4.69-15.07-7.17-22.55c-0.92-2.77-2.36-5.37-3.42-8.09
                                        c-0.61-1.55-1.35-3.23-1.3-4.82c0.13-4.21,0.71-8.41,1.02-12.61c0.13-1.8,0.02-3.62,0.02-5.92c-4.32,3.68-9.75,6.09-11.11,9.93
                                        c-2.61,7.42-8.77,11.72-12.72,17.83c-1.5,2.33-5.38,5.15-7.49,4.68c-7.4-1.64-14.42-0.51-21.62,0.54c-3.13,0.46-6.58-0.63-9.56,0.19
                                        c-6.05,1.67-14.27,0.59-15.26,10.08c-5.27,3.07-3.12,7.35-2.15,11.65c0.33,1.45-0.76,4.63-1.23,4.63
                                        c-7.68,0.06-10.93,5.53-14.19,11.07c-0.08,0.13-0.35,0.16-0.41,0.29c-0.64,1.5-1.31,3-1.87,4.53c-1.15,3.19-1.43,8.39-3.49,9.22
                                        c-5.81,2.34-4.77,6.21-4.07,10.34c1.07,6.34,2.08,12.7,3.59,18.95c1.46,6.05,2.65,12.42,5.57,17.76c2.35,4.29,1.63,7.17-0.32,10.72
                                        c-0.6,1.09-1.44,2.8-1,3.57c2.1,3.75,4.22,7.6,7.04,10.8c3.02,3.42,6.79,7.01,4.85,11.41c-1.74,3.96,0.13,5.49,1.96,7.84
                                        c2.59,3.32,6.86,6.58,7.2,10.17c1.12,11.84,11.13,18.99,14.24,29.59c0.27,0.92,1.66,1.51,2.9,2.58c3-4.36,6.76-5.97,11.91-4.85
                                        c1.41,0.31,4.02-1.53,4.96-3.06c2.29-3.77,4.57-3.2,6.71-0.43C253.77,560.53,255.23,563.61,256.85,566.34z"/>
                                    <path  
                                    d="M424.59,239.53c-6.43,0-13.28-2.05-17.08,0.48c-5.85,3.89-11.35,3.33-16.85,2.44
                                        c-8.99-1.45-17.97-2.74-26.08-8.28c-6.55-4.47-9.66-13.01-18.25-14.73c-0.25-0.05-0.47-0.48-0.62-0.78
                                        c-3.29-6.87-8.63-7.71-15.26-5.48c-1.7,0.57-3.84,0.71-5.54,0.23c-6.08-1.75-8.58,2.87-10.1,6.65c-1.3,3.21-1.42,8.23,0.29,10.99
                                        c2.39,3.87,4.26,7.67,6.03,11.86c2.99,7.08,3.47,13.93,2.72,21.33c-0.59,5.87-1.64,12.27,0.05,17.65
                                        c2.64,8.41,2.27,16.54,0.24,24.48c-1.16,4.54-4.73,8.67-7.87,12.43c-3.01,3.61-7.11,6.3-10.32,9.78c-4.32,4.69-8.9,7.88-17.05,4.32
                                        c0,4.25-0.01,7.49,0,10.73c0.02,4.67-0.03,9.35,0.13,14.02c0.13,3.82,0.21,7.69,0.91,11.42c0.51,2.71,2.33,5.15,3.01,7.84
                                        c1.37,5.41,2.02,11.02,3.62,16.35c1.15,3.83,3.87,7.19,4.96,11.03c1.42,5,2.62,9.67,7.91,12.17c1.49,0.7,2.28,2.83,3.45,4.26
                                        c1.69,2.09,3.29,4.27,5.17,6.17c3.98,4.03,8.69,7.48,12.04,11.96c3.65,4.89,8.31,7.62,13.83,8.71c5.11,1.01,10.46,1.24,15.69,1.13
                                        c7.94-0.16,11.45-5.41,8.55-12.69c-3.4-8.49-6.74-17.08-14.06-23.26c-3.16-2.67-4.07-5.77-2.33-9.97c0.99-2.4,0.42-5.47,0.41-8.23
                                        c-0.01-2.34-0.26-4.68-0.34-7.03c-0.06-1.73-0.01-3.47-0.01-4.37c-4.68,0-9.61,1.43-12.3-0.42c-2.51-1.72-4.49-7.57-3.46-10.44
                                        c2.24-6.24,8.3-4.44,13.81-2.76c0.39-6.21,0.74-11.76,1.12-17.89c1.79,0.74,2.75,0.85,3.26,1.39c3.91,4.16,7.86,8.29,11.53,12.66
                                        c2.55,3.03,5.28,4.59,9.47,4.9c2.76,0.2,5.63,2.55,7.89,4.57c1.53,1.37,2.03,3.86,3.12,5.77c2.43,4.29,4.78,4.45,8.12,0.88
                                        c0.96-1.02,2.37-1.9,3.72-2.21c3.25-0.75,6.58-1.13,9.88-1.66c2.89-0.46,5.59-0.4,6.45,3.14c1.02,4.21,2.02,8.44,2.78,12.71
                                        c0.59,3.35-0.41,8.33,1.47,9.77c2.24,1.72,6.78,0.43,10.32,0.44c0.17,0,0.34,0,0.51,0c9.09,0,11.06-3.42,6.83-11.58
                                        c-1.4-2.7-2.93-5.67,0.59-8.12c5.03-3.49,5.36-5.54,3.54-12c-0.46-1.63,1.05-4.31,2.42-5.78c0.91-0.97,4.31-1.38,4.69-0.8
                                        c2.53,3.87,6.34,2.83,9.85,3.34c3.53,0.51,4.7-1.77,2.69-3.51c-6.2-5.38-4.32-9.85-0.35-15.45c1.8-2.53,3.15-3.79,6.16-4.11
                                        c1.84-0.19,4.37-1.3,5.13-2.76c1.96-3.8,4.15-7.82,1.11-12.37c-0.77-1.15-0.22-3.21-0.2-4.85c0.07-5.38,0.32-10.76,0.21-16.14
                                        c-0.08-4.29-1.24-8.64-0.78-12.83c1.36-12.16,3.1-24.02-1.15-36.23c-2.23-6.42-3.73-12.52-11.29-16.48
                                        c-1.96,1.43-5.75,2.82-7.05,5.45c-1.83,3.73-3.42,6.67-7.67,7.98c-1.74,0.53-3.35,2.35-4.46,3.96c-5.95,8.66-12.14,7.37-21.2,4.57
                                        c-1.01-0.31-2.24-2.94-1.9-4.04C418.87,255.24,421.46,248.35,424.59,239.53z"/>
                                    <path   
                                    d="M419.15,263.51c8.13,3.43,12.8,2.87,16.98-1.18c3.07-2.97,6.76-5.28,9.95-8.14
                                        c1.28-1.15,1.82-3.1,2.97-4.45c1.87-2.2,3.82-4.37,5.99-6.27c6.29-5.51,13.26-10.39,18.81-16.55c2.39-2.65,4.09-4.66,7.66-5.49
                                        c5.91-1.37,10.72-3.8,12.06-11.12c1-5.43,4.24-10.45,6.49-15.65c1.64-3.78,3.57-7.45,4.87-11.34c1.81-5.43,2.86-11.13,4.79-16.51
                                        c1.74-4.85,4.28-9.4,6.39-14.12c1.77-3.95,3.14-8.11,5.21-11.89c2.44-4.47,1.46-7.41-3.42-8.31c-1.66-0.31-3.37-0.35-5.04-0.64
                                        c-8.99-1.58-17.98-3.12-26.94-4.85c-3.76-0.72-7.43-2.67-11.15-2.68c-13.56-0.02-27.14,0.3-40.69,0.97
                                        c-7.37,0.36-14.17,0.59-20.74-4.16c-10.83-7.82-22.81-14.11-33.27-22.35c-9.44-7.43-17.31-16.71-28.89-21.44
                                        c-5.94-2.43-16.93,1.36-19.47,7.51c-2.58,6.24-5.32,12.48-8.66,18.33c-2.05,3.59-4.83,7.29-8.23,9.44c-5.18,3.28-5.32,7.7-5.12,12.7
                                        c0.29,7.21,0.73,14.41,0.76,21.62c0.04,10.06-2.2,20.23,3.28,29.85c1.29,2.26,1.43,6.83-0.02,8.66c-3.95,4.96-2.04,9.89-0.82,14.48
                                        c2.65,9.99,5.95,12.22,14.51,11.45c1.32-0.12,2.61-0.62,3.89-1.03c6.47-2.12,10.49-0.86,14.5,4.49c0.8,1.07,1.88,2.9,2.68,2.83
                                        c4.59-0.45,5.82,3.21,8.08,5.8c2.22,2.54,4.82,4.74,7.31,7.03c7.38,6.81,16.95,7.8,26.16,9.46c4.07,0.74,9.7,1.93,12.32-0.08
                                        c8.59-6.57,16.67-0.49,25.35-1.92C424.56,247.35,421.85,255.44,419.15,263.51z"/>
                                    <path   
                                    d="M679.72,444.25c-0.7-0.09-2.32-0.15-3.87-0.52c-1.24-0.3-2.7-0.67-3.53-1.52
                                        c-3.45-3.53-2.17-10.87,2.04-13.43c1.21-0.74,1.96-2.18,3.05-3.16c1.22-1.09,2.56-2.8,3.9-2.85c5.11-0.19,6.07-3.1,6.06-7.41
                                        c-0.02-5.58,2.08-6.72,7.32-4.59c2.31,0.94,4.76,1.57,7.2,2.15c3.38,0.8,5.57-0.17,6.06-4.06c0.2-1.56,0.88-3.43,1.99-4.43
                                        c3-2.68,6.21-5.2,9.63-7.33c1.88-1.17,4.3-1.87,6.52-1.98c5.8-0.31,10.83-2.05,15.25-5.94c1.76-1.55,4.23-3.43,6.26-3.31
                                        c6.22,0.38,8.54-4.13,11.35-7.98c3.24-4.44,4.08-8.98-0.55-13.33c-1.02-0.96-1.55-2.66-1.86-4.11c-0.69-3.26-2.5-4.98-5.84-5.23
                                        c-4.63-0.34-6.61-2.93-6.11-7.51c0.35-3.21-0.26-5.67-3.44-7.64c-2.14-1.33-4.25-3.9-4.82-6.29c-1.53-6.38-4.32-9.22-11.55-8.99
                                        c-7.91,0.25-7.68-0.44-12.87,5.79c-3.82,4.59-3.61,9.24-2.98,14.71c0.54,4.77,0.02,10.06-1.61,14.54c-1.05,2.88-1.48,4.77-0.11,7.41
                                        c0.69,1.34,1.23,3.35,0.72,4.6c-1.28,3.16-10.23,6.5-12.85,5.2c-3.62-1.8-4.89-8.66-2.54-13.23c0.79-1.54,1.34-3.2,2.01-4.81
                                        c-7-2.76-10.28-8.04-12.11-14.08c-1.61-5.31-2.3-10.91-3.34-16.39c-1.09-5.74-3.05-6.3-8.34-5.91c-5.26,0.38-8.23,2.03-9.61,7.08
                                        c-0.29,1.07-1.83,2.22-3.01,2.55c-4.63,1.28-9.31,2.72-14.06,3.21c-8.71,0.91-17.15-1.33-25.45,6.03
                                        c-6.31,5.59-16.58,8.11-25.96,6.67c-5.29-0.81-7.11,1.27-9.25,5.28c-1.91,3.58-4.36,7.8-7.7,9.51c-7.62,3.89-15.87,6.64-24.01,9.42
                                        c-4.14,1.42-8.6,1.9-12.84,2.8c1.67,6.85,9.34,11.52,7.33,19.82c-0.09,0.36,0.4,0.93,0.73,1.3c6.73,7.46,4.89,15.78,2.18,24.05
                                        c-0.29,0.89-2.41,1.69-3.64,1.63c-1.6-0.08-3.17-1.04-4.74-1.68c-4-1.64-10.83,3.79-11.17,8.06c-0.36,4.38-1.52,8.81-3.08,12.93
                                        c-1.67,4.42-4.22,8.51-7.1,14.13c9.04-1.41,13.2,1.97,15.04,8.89c0.26,0.97,2.54,2.12,3.72,1.96c2.28-0.32,4.55-1.31,6.62-2.4
                                        c2.63-1.38,4.74-1.69,7.45,0.22c4.92,3.46,9.99,6.87,15.41,9.45c4.51,2.15,5.34,5.51,5.75,9.76c0.24,2.42,0.68,5.44,2.23,6.98
                                        c2.78,2.77,6.42,4.69,9.8,6.8c0.66,0.41,2.31,0.43,2.66-0.02c3.13-4.03,5.47-1.08,7.82,1.04c2.11-1.28,3.92-2.53,5.87-3.54
                                        c2.59-1.34,5.03-0.93,5.36,2.24c0.57,5.6,3.22,5.43,7.62,4.01c7-2.26,8.17-1.36,8.98,4.96c5.9,1.9,9.85-1.65,12.23-11.53
                                        c-1.39-3-2.53-6.13-2.7-9.31c-0.34-6.38,0.07-12.79-0.24-19.17c-0.19-3.89,1.37-5.63,4.86-4.11c10.06,4.37,19.79,9.28,27.79,17.06
                                        c0.87,0.85,2.78,1.51,3.74,1.12c0.82-0.34,0.97-2.31,1.39-3.58c0.51-1.55,0.52-3.9,1.57-4.54c4.36-2.64,7.85-5.31,6.92-11.28
                                        c-0.33-2.13,1.01-4.48,1.1-6.74C680.45,450.93,680.01,448.25,679.72,444.25z"/>
                                    <path   
                                    d="M931.95,318.3c-3.9-1.48-7.39-3.25-11.1-4.08c-4.78-1.08-9.72-1.4-14.6-2.06
                                        c-4.23-0.56-7.47-1.88-7.39-7.18c0.02-1.62-1.5-4.52-2.6-4.66c-3.25-0.42-8.66-0.9-9.58,0.71c-2.63,4.62-4.61,10.27-4.59,15.51
                                        c0.02,3.68-0.57,5.23-3.32,7.23c-2.57,1.86-5.61,4.41-6.32,7.2c-1.49,5.81-4.3,10.5-8,15.03c-6.81,8.32-15.34,12.85-26.15,12.89
                                        c-3.9,0.01-7.86-0.41-11.69,0.11c-2.6,0.35-5.7,1.38-7.4,3.2c-5.1,5.43-9.53,11.47-14.42,17.1c-1.21,1.4-3.2,3.09-4.78,3.04
                                        c-5.16-0.19-4.83,3.25-6.18,6.69c-3.63,9.25,3.08,20.17-4.98,28.49c-0.19,0.2-0.03,0.69-0.16,0.99c-1.58,3.66-3.26,7.27-4.74,10.96
                                        c-0.86,2.15-1.91,4.42-1.98,6.67c-0.43,15.38-0.62,30.76-0.88,46.14c-0.02,1.02-0.27,2.17,0.11,3.02c0.55,1.22,1.38,2.88,2.44,3.21
                                        c6.73,2.08,13.48,4.93,20.38,5.41c6.49,0.46,12.05,2.25,17.76,4.9c5.79,2.7,13.93,13.08,14.1,19.46c0.07,2.74,1.01,3.62,3.69,4.25
                                        c3.94,0.92,9.02,1.69,11.16,4.43c3.1,3.98,6.05,8.06,9.35,11.98c3.38,4.02,6.34,6.29,11.64,5.36c4.12-0.72,8.25-1.45,12.4-1.94
                                        c8.83-1.04,17.78-1.76,20.07-12.95c0.16-0.78,0.73-1.54,1.27-2.18c4.81-5.72,9.25-11.83,14.62-16.97c3.84-3.67,7.98-6.37,9.24-12.28
                                        c0.64-2.99,1.48-5.23-0.4-7.86c-3.22-4.5-6.87-8.88-4.86-15.14c0.21-0.66-0.73-1.7-1.67-3.67c-2.59,2.81-4.91,4.93-6.74,7.4
                                        c-3.06,4.15-4.24,4.4-7.68,0.54c-1.23-1.38-1.9-3.27-3.19-4.55c-2.17-2.14-4.33-4.9-7.03-5.79c-12.26-4.04-15.91-14.85-20.26-25.02
                                        c-0.89-2.07-1.15-5.58,3.17-6c1.74-0.17,3.46-0.97,5.11-1.67c1.97-0.84,3.77-2.17,5.8-2.79c4.66-1.43,9.34-2.96,14.13-3.7
                                        c5.32-0.82,4.96-3.83,4.11-7.56c-7.29,0.13-9.22-2.21-11.26-8.99c-1.97-6.53,0.62-12.46,0.71-18.66c0.04-2.75,1.92-4.27,4.94-3.48
                                        c3.73,0.97,6.6,0.97,6.94-4.17c0.1-1.56,2.2-2.9,3.02-4.52c1.3-2.59,2.81-5.24,3.29-8.03c0.82-4.79-0.8-11.07,1.73-14.23
                                        c3.57-4.46,3.29-9.05,4.46-13.67c0.53-2.08,1.54-4.05,2.43-6.02c0.94-2.07,2.41-3.98,2.91-6.13
                                        C930.19,329.19,930.93,324.02,931.95,318.3z"/>
                                    <path   
                                    d="M341.66,768.01c4.91,2.87,9.42,5.43,13.83,8.15c0.5,0.31,0.72,1.59,0.57,2.32
                                        c-0.86,4.13-1.25,8.52-3.01,12.26c-1.62,3.44-4.25,5.95,0.25,9.21c0.91,0.66,0.59,3.55,0.26,5.31c-1.29,6.89-1.04,7.19,6.04,5.57
                                        c7.85-1.8,16.44,6.21,15.41,14.19c-0.21,1.64-0.03,4.08,1,5c5.1,4.58,10.56,8.77,15.87,13.13c1.04,0.85,2.18,1.67,2.95,2.74
                                        c3.94,5.45,10.99,5.71,14.06-0.17c4.07-7.78,10.28-12.22,18.34-14.82c3.12-1,4.83-0.72,5.2,2.98c0.35,3.54,2.09,5.66,5.19,8.21
                                        c4.67,3.84,12.65,6.22,11.44,15.17c-0.21,1.55,1.99,4.23,3.72,5.06c2.83,1.36,4.58-0.82,5.66-3.44c1.18-2.85,3.23-3.37,6.39-3.63
                                        c4.87-0.39,5.84,3.18,8.24,7.04c1.05-3.22,1.18-5.82,2.53-7.33c2.74-3.07,6.22-5.44,9.22-8.3c2.53-2.41,5.01-2.29,7.03,0.17
                                        c2.21,2.7,4.59,2.85,7.65,2.01c5.89-1.62,13-11.87,13.94-18.34c1.08-7.44,2.05-15.49,5.64-21.82c3.68-6.47-0.1-10.61-1.21-15.75
                                        c-0.8-3.7-2.29-7.43-2.17-11.1c0.19-5.6,1.52-11.16,2.42-17.05c-1.68-0.26-3.02-0.92-3.89-0.53c-5.72,2.56-7.47-1.12-8.25-5.43
                                        c-0.92-5.08-2.06-9.73-6.81-12.79c-0.95-0.61-1.2-2.7-1.31-4.14c-0.68-9.22-0.64-9.22-10.02-7.31c-1.33,0.27-2.68,0.75-4,0.69
                                        c-1.06-0.05-2.7-0.5-3.03-1.25c-2.28-5.14-4.77-10.27-6.21-15.67c-0.97-3.66-0.2-6.77,3.52-10.28c3.64-3.43,6.48-5.78,11.37-5.81
                                        c0.84-0.01,2.15-1.16,2.43-2.04c1.4-4.33,2.82-8.69,3.68-13.14c1.09-5.59,6-10.04,11.76-10.04c1.29,0,2.57-0.18,4.05-0.29
                                        c-1.13-4.09-2.33-7.77-3.15-11.53c-0.75-3.45-1.09-6.99-1.75-11.4c-1.61,1.71-3.39,3.6-5.18,5.48c-1.7,1.79-3.1,4.22-5.19,5.24
                                        c-10,4.9-10.07,4.76-8.28,16.1c0.05,0.34,0.14,0.67,0.18,1c1.24,9.04-3.72,15.06-12.74,15.6c-4.92,0.29-7.47,3.64-7.78,7.93
                                        c-0.59,8.15-0.71,16.36-0.45,24.53c0.2,6.23-1.15,7.59-6.89,5.17c-1.2-0.51-2.2-1.52-3.26-2.34c-3.74-2.88-7.37-5.91-11.26-8.56
                                        c-0.84-0.57-2.92-0.22-3.89,0.47c-4.11,2.91-8.11,6-11.97,9.24c-3.02,2.54-5.67,1.45-7.39-1.05c-1.03-1.5-1.33-5.51-0.62-5.89
                                        c4.5-2.42,3.32-6.56,4.24-10.45c-3.68,0-7.35-0.95-10.18,0.19c-7.13,2.86-19.77,1.15-25.27-4.32c-3.71-3.68-15.98-1.89-19.01,2.25
                                        c-3.2,4.37-6.4,9.51-10.89,11.99c-6.22,3.44-8.03,8.62-9.86,14.45c-0.75,2.39-1.65,5.04-3.31,6.79
                                        C341.87,753.65,343.31,761.27,341.66,768.01z"/>
                                    <path   
                                    d="M1018.99,755.59c-0.36,1.44-0.62,1.93-0.58,2.39c0.18,2.03,0.45,4.04,0.68,6.07
                                        c0.67,5.89-0.11,6.68-5.88,6.24c-1.85-0.14-3.73-0.29-5.56-0.07c-7.59,0.9-13.92,6.74-22.05,5.67c-0.55-0.07-1.24,0.51-1.79,0.88
                                        c-8.84,5.91-18.07,7.16-28.39,3.88c-3.25-1.03-7.63,0.53-11.14,1.84c-1.17,0.44-1.35,3.76-1.82,5.8c-1.14,4.91-1.94,9.97-9.04,9.14
                                        c-0.84-0.1-2.44,1.57-2.78,2.69c-1.5,5.04-3.56,9.52-8.04,10.99c0.35,5.66,0.66,10.73,0.96,15.79c0.31,5.16,0.45,10.33,0.98,15.46
                                        c0.33,3.2,1.23,6.34,1.91,9.68c2.83,0.13,7.09-0.22,6.38,4.53c-1.13,7.55,0.18,14.43,3.8,20.92c1.16,2.07,2.87,4.32,4.9,5.32
                                        c8.63,4.24,15.65,10.08,20.29,18.58c1.93,3.55,3.81,6.46,8.83,6.03c2.1-0.18,4.44,2.1,6.62,3.33c1.46,0.82,2.83,2.54,4.26,2.56
                                        c8.78,0.14,11.27,6.13,13.04,13.13c1.04,4.1,1.86,8.43,3.89,12.03c1.34,2.39,5.74,5.73,6.89,5.12c6.56-3.49,8.82,0.95,11.81,5
                                        c2.78,3.77,5.53,7.7,8.99,10.77c3.03,2.68,7.02,3.2,10.77,0.42c3.63-2.69,7.41-5.17,10.99-7.92c0.8-0.62,1.47-1.94,1.5-2.95
                                        c0.11-5.24,0.87-10.7-0.33-15.66c-1.09-4.55-0.36-8.23,1.12-12.26c2.68-7.3,5.25-14.65,7.67-22.05c0.9-2.74,0.88-5.15-1.71-7.74
                                        c-3.21-3.2-5.01-7.42-4.15-12.52c0.49-2.88,0.38-6.37-0.84-8.92c-3.08-6.41-3.7-12.74-2.25-19.62c1.37-6.47,4.32-12.99,0.32-19.7
                                        c-0.75-1.25-0.62-3.5-0.06-4.98c1.59-4.18-0.17-7.11-2.8-10.03c-1.93-2.14-3.38-4.74-5.47-6.69c-2.69-2.52-3.14-5.27-2.64-8.74
                                        c1.02-7.08,1.97-14.2,2.39-21.33c0.19-3.26-0.55-6.72-1.53-9.88c-1.42-4.58-3.43-8.98-5.49-14.23c-1.02,0.48-3.41,0.85-3.76,1.9
                                        c-1.66,5.05-4.09,3.99-7.12,1.48C1021.77,757.15,1020.59,756.59,1018.99,755.59z"/>
                                    <path   
                                    d="M285.53,878.45c0.91-1.58,2.49-3.81,3.53-6.26c1.57-3.7,4.42-7.89,3.75-11.29
                                        c-1.09-5.56-0.65-10.65,0.27-15.99c0.91-5.32,1.73-10.67,2.18-16.04c0.38-4.48-3.15-6.63-7.24-4.66c-1.52,0.73-2.83,2.1-4.4,2.44
                                        c-1.97,0.43-4.73,0.95-6.06-0.04c-6.25-4.63-5.51-9.58,1.41-12.83c4.67-2.19,9.7-4,13.7-7.1c2.92-2.27,5.03-2.71,8.17-1.4
                                        c2.36,0.99,4.91,1.54,8.44,2.61c-0.52-8.23-0.92-15.24-1.47-22.24c-0.05-0.68-0.98-1.31-1.56-1.91c-4.6-4.8-5.42-10.72-5.37-17.03
                                        c0.08-10.3,3.08-20.89-3.4-30.57c-1.19-1.77-0.91-4.52-1.31-6.82c-0.32-1.8,0-4.39-1.08-5.25c-3.48-2.82-7.47-5-11.59-7.64
                                        c0,3.13,0.3,5.94-0.06,8.67c-0.71,5.29-1.68,10.55-2.82,15.76c-0.32,1.44-1.85,3.9-2.44,3.79c-5.5-1-11.74-1.2-16.13-4.1
                                        c-5.16-3.41-9.97-7.26-15.98-8.94c-3.63-1.02-5.48-2.35-5.01-6.63c0.54-4.98,0.13-10.06,0.13-15.1c-0.64,0.27-1.29,0.53-1.93,0.8
                                        c-2.95-2.31-6.32-6.92-8.76-6.46c-5.65,1.06-10.8-0.44-16.17-0.51c-6.1-0.08-7.49,6.18-11.18,9.37c-4.06,3.51-3.7,5.68,1.17,8.01
                                        c3.44,1.65,3.83,4,1.88,7c-2.63,4.03-7.24,4.7-14.77,1.93c-4.08-1.5-4.21-4.7-3.29-8.37c0.31-1.23-0.06-2.62-0.12-3.77
                                        c-0.61-0.31-0.91-0.59-1.2-0.59c-2.18-0.04-4.36-0.02-6.56-0.02c-1.58,5.98-3.22,6.84-9.34,4.71c-2.24-0.78-4.5-1.57-6.6-2.64
                                        c-1.14-0.58-2.93-1.97-2.79-2.64c1.21-5.56-1.5-7.64-6.47-8.38c-0.94-0.14-1.61-1.65-2.53-2.37c-1.9-1.51-3.88-2.91-5.83-4.36
                                        c-0.58,0.33-1.17,0.66-1.75,0.99c12.42,12.83,11.51,29.54,13.9,45.01c4.17,0,7.71-0.02,11.25,0.01c3.07,0.02,4.69,1.42,4.66,4.67
                                        c-0.03,3.73-0.06,7.48,0.33,11.18c0.14,1.34,1.44,3.58,2.33,3.64c3.61,0.26,4.31,2.04,4.58,5.28c0.34,4.06,2.7,4.56,6.07,2
                                        c1.19-0.9,3.11-1.62,4.48-1.35c4.46,0.87,9.22,9.23,8.96,13.81c-0.33,5.94-3.55,8.23-8.57,8.02c1.88,6.44,3.26,12.52,5.53,18.24
                                        c0.9,2.27,3.67,5.18,5.77,5.33c8.1,0.58,12.43,5.62,16.04,11.8c4.37,7.47,8.27,15.21,12.86,22.53c2.19,3.5,5.07,6.91,8.41,9.27
                                        c6.53,4.6,14.97,6,20.76,12.1c1.06,1.12,3,1.69,4.62,1.93C272.92,876.94,278.95,877.61,285.53,878.45z"/>
                                    <path  
                                    d="M674.44,261.5c1.84-1.06,2.39-1.42,2.98-1.72c5.82-2.99,10.79-8.44,18.55-5.37
                                        c3.26,1.29,5.86-0.63,8.99-2.5c4.31-2.56,6.81-4.79,6.39-9.83c-0.04-0.47,0.72-1.33,1.23-1.45c3.39-0.79,2.87-3.47,3.39-5.94
                                        c0.44-2.14,1.62-5.46,3.06-5.8c4.29-1.02,8.89-1.23,13.33-0.99c4.14,0.22,6.84-1.83,6.56-5.94c-0.24-3.56-0.5-7.15-0.3-10.71
                                        c0.07-1.26,1.19-3.03,2.31-3.58c6.44-3.15,18.23,2.09,21.09,8.76c1.54,3.6,3.91,6.84,5.91,10.24c3.69-6.4,5.93-12.24,8.14-18.09
                                        c0.11-0.3,0.12-0.69,0.05-1.01c-0.8-3.31-1.84-6.58-2.39-9.93c-0.39-2.4-1.11-6.29,0.04-7.11c3.09-2.2,7.01-3.89,10.77-4.35
                                        c4.77-0.58,9.71,0.26,15.04,0.5c-5.57-8.19-11.24-15.15-15.29-22.95c-2.79-5.37-6.09-6.88-11.44-6.13
                                        c-4.31,0.61-7.81-0.88-11.16-3.69c-1.2-1.01-4.02-1.09-5.63-0.44c-6.01,2.44-11.77,5.5-17.76,8.01c-1.75,0.73-4.03,0.83-5.93,0.47
                                        c-8.03-1.54-13.48-7.62-14.46-15.67c-0.15-1.25-0.41-2.48-0.94-5.65c-3.56,5.86-6.96,3.33-10.53,2.3
                                        c-8.1-2.35-16.18-5.22-24.47-6.38c-9.85-1.38-19.94-0.9-29.87-1.81c-3.65-0.33-6.38,0.29-8.54,2.98
                                        c-4.95,6.17-9.54,12.64-14.7,18.62c-5.01,5.81-8.58,12.39-9.57,19.78c-0.51,3.85,0.6,8.82,2.83,11.96
                                        c5.63,7.92,12.78,14.74,18.43,22.64c5.78,8.08,10.32,17.04,15.79,25.37c1.61,2.45,4.25,5.12,6.89,5.79
                                        c5.53,1.39,7.14,4.66,7.77,9.76C671.42,254.89,673.16,257.96,674.44,261.5z"/>
                                    <path  
                                    d="M393.36,521.65c-5.54-3.38-8.32-8.27-10.37-14.1c-2.73-7.75-6.98-15-9.38-22.83
                                        c-1.16-3.79-0.25-8.63,1.06-12.54c0.52-1.53,4.66-2.2,7.25-2.61c4.66-0.74,9.62-0.28,14.05-1.64c3.82-1.18,5.68-0.08,7.67,2.64
                                        c1.88,2.56,3.53,5.61,6.05,7.32c4.05,2.74,5.4,6.25,5.39,10.74c-0.01,4.2,0,8.39,0,14.11c2.88-1.36,4.58-2.27,6.36-2.97
                                        c1.61-0.63,4.15-1.98,4.82-1.38c3,2.67,6.58,2.59,10.08,3.39c4.52,1.02,8.41-0.3,10-4.41c-1.27-2.78-2.6-5.7-2.5-8.58
                                        c0.32-9.42,3.29-18.63,2.06-28.22c-0.26-2.04,0.73-4.23,1.14-6.36c0.26-1.37,0.51-2.74,0.84-4.46c-2.87-0.26-5.15-0.98-7.18-0.55
                                        c-6.92,1.46-8.53,0.42-7.96-6.52c0.26-3.11-0.05-5.41-2.79-7.48c-2.32-1.76-3.67-4.09-1.37-7.45c0.96-1.41,0.62-5.04-0.58-6.35
                                        c-3.3-3.61-3.11-6.84-0.6-10.5c2.07-3.02,0.27-3.97-2.38-4.17c-1.85-0.14-3.99,0.7-5.52,0.01c-1.98-0.89-4.61-2.62-4.99-4.4
                                        c-1.24-5.87-1.77-11.91-2.23-17.91c-0.33-4.24-2.46-5.04-5.73-3.38c-4.28,2.17-9.67,0.1-13.58,4.95c-2.26,2.8-6.72-0.21-9.47-4.79
                                        c-1.83-3.04-4.64-7.91-6.89-7.85c-9.28,0.26-12.82-6.34-17.35-12.1c-1.56-1.99-3.24-3.88-4.87-5.82c-0.47,0.24-0.93,0.48-1.4,0.73
                                        c0,1.81,0.09,3.62-0.02,5.43c-0.19,3.17,0.61,7.41-1.1,9.17c-1.41,1.45-5.84-0.01-8.93-0.23c-3.41-0.25-5.99,2.55-4.74,5.21
                                        c3.06,6.55,3.46,6.71,10.5,4.26c4.02-1.4,5.88,0.15,5.67,4.48c-0.15,3.03,0.03,6.07,0.02,9.1c0,3.08,0.67,6.39-0.25,9.17
                                        c-1.24,3.77-1,6.49,2.11,8.97c7.98,6.36,10.85,15.84,14.55,24.69c2.44,5.83-0.78,13.03-6.3,13.95
                                        c-11.63,1.93-29.77,1.89-38.13-12.44c-1.72-2.95-4.73-5.15-7.19-7.65c-2.8-2.84-5.69-5.61-8.48-8.46c-1.99-2.03-3.89-4.16-6.26-6.7
                                        c-4.34,9-7.48,17.13-11.99,24.42c-4.98,8.05-5.27,16.48-4.98,25.33c0.06,1.69-0.92,3.43-1.51,5.12c-0.56,1.6-1.25,3.16-1.88,4.74
                                        c1.71,0.51,3.4,1.3,5.15,1.45c1.65,0.14,3.34-0.46,5.03-0.61c5.94-0.52,11.82-1.2,16.46,4.12c1.34,1.55,3.74,2.27,5.75,3.12
                                        c5.2,2.21,12.76,1.11,13.36,9.68c0.01,0.13,0.21,0.25,0.34,0.37c5.14,4.88,10.38,9.65,15.39,14.66c4.64,4.64,9.14,9.32,10.43,16.32
                                        c1.2,6.51,5.15,9.45,11.55,8.79c4.75-0.49,9.37-2.31,14.12-2.8C387.24,529.27,390.3,525.85,393.36,521.65z"/>
                                    <path  
                                    d="M933.08,575.89c-3.94-2.89-7.65-5.51-12.88-3.27c-0.88,0.38-3.62-1.04-3.76-1.91
                                        c-0.75-4.74-3.94-4.79-7.52-4.64c-1.33,0.05-2.78,0.03-4.01-0.42c-9.41-3.45-13.35-1.79-17.35,7.29c-0.95,2.16-2.22,4.18-3.45,6.2
                                        c-3.67,6.07-7.55,12.02-11.03,18.19c-2.25,3.99-5.84,4.85-9.58,2.39c-3.62-2.38-7.67-4.12-11.57-6.16
                                        c-1.61,5.11-3.31,8.85-3.9,12.75c-0.73,4.81-1,9.8-0.56,14.63c0.22,2.4,1.94,5.25,3.86,6.81c7.01,5.71,6.71,13.7-1.04,18.08
                                        c-7.24,4.08-7.44,4.69-4.29,12.28c3.31,0.51,6.47,1.49,9.58,1.33c3.04-0.15,6-1.53,9.04-2.22c0.75-0.17,1.71,0.1,2.46,0.44
                                        c4.29,1.95,6.58,0.71,9.36-3.41c6.07-8.98,7.67-8.24,13.96-1.11c2.31,2.62,3.63,6.26,6.23,8.45c2.75,2.3,4.86,4.38,5.19,8.13
                                        c0.12,1.28,1.35,3.43,2.22,3.51c5.93,0.53,6.56,4.82,7.45,9.3c0.48,2.41,1.41,4.88,2.73,6.94c3.02,4.71,0.58,8.87-1.82,12.21
                                        c-1.19,1.65-5.45,2.04-8.04,1.54c-6.61-1.29-12.53,3.29-12.23,10.06c2.65,0.12,5.48-0.34,7.88,0.48c3.92,1.33,7.54,3.54,11.33,5.29
                                        c2.89,1.34,6.15,2.13,8.71,3.93c5.93,4.19,11.16,9.48,17.37,13.13c6.24,3.67,13.13,6.72,20.12,8.46c4.6,1.14,7.3,3.37,10.14,6.64
                                        c6.15,7.1,12.42,14.12,19.16,20.65c1.36,1.31,5.05,0.53,7.56,0.12c0.62-0.1,1.64-3.15,1.14-3.81c-3.16-4.12-6.4-8.26-10.2-11.77
                                        c-3.15-2.92-6.82-11.18-4.28-12.9c6.54-4.43,4.94-13.11,10.35-17.92c0.43-0.38,0.21-1.98-0.2-2.73c-1.59-2.86-2.48-6.22-6.47-7.22
                                        c-1.5-0.38-3.76-2.62-3.63-3.76c0.87-7.11-2.75-12.48-5.65-18.38c-1.47-2.99-0.57-7.15-0.73-10.78c3.28,0.36,6.57,0.99,9.85,0.98
                                        c1.78-0.01,5.1-1.19,5.08-1.7c-0.16-2.88,1.74-7.38-2.69-8.12c-9.39-1.58-18.52-4.96-28.45-3.36c-6.99,1.13-7.66-0.05-7.82-6.88
                                        c-0.03-1.16-0.59-2.31-0.66-3.48c-0.41-6.83-1.24-13.69,1.93-20.2c1.4-2.88,0.3-5.07-2.83-4.72c-4.84,0.53-7.49-1.82-10.05-5.37
                                        c-1.05-1.46-3.36-1.96-4.92-3.13c-1.22-0.92-3.19-2.36-3.05-3.31c0.63-4.34-1.47-7.5-3.72-10.68c-2.5-3.53-1.67-6.89-0.07-10.54
                                        c0.94-2.15,1.34-4.76,1.2-7.11C928.11,588.22,927.18,581.36,933.08,575.89z"/>
                                    <path  
                                      d="M792.61,550.52c2.68-4.9,4.63-8.94,7.01-12.7c2.33-3.67,4.87-7.26,7.74-10.52
                                        c2.28-2.58,5.18-4.62,7.81-6.87c2.51-2.15,4.86-4.57,7.62-6.35c3.93-2.52,5.52-8.43,2.01-10.57c-4.57-2.78-9.55-5.27-14.69-6.66
                                        c-9.35-2.53-18.97-4.03-28.39-6.32c-1.37-0.33-3.22-2.63-3.21-4.01c0.01-15.71,0.37-31.43,0.55-47.14
                                        c0.05-4.86,0.72-9.26,4.21-13.17c1.65-1.85,1.96-4.92,2.78-7.47c0.46-1.42,0.22-3.57,1.14-4.25c4.14-3.03,2.94-7.14,2.91-11.11
                                        c-0.02-3.16-0.04-6.39,0.53-9.48c0.82-4.48-2-8.95-6.36-8.39c-5.46,0.69-9.54-1.72-13.56-4.6c-2.44-1.75-4.64-3.82-7.18-5.95
                                        c-0.58,0.86-1.07,1.49-1.47,2.17c-3.26,5.53-6.16,11.34-14.14,11.05c-1.46-0.05-2.91,1.92-4.49,2.67
                                        c-5.33,2.54-10.52,5.98-16.15,7.08c-4.62,0.9-15.68,7.07-16.81,11.93c-1.11,4.78-4.56,6.96-9.32,5.74
                                        c-3.48-0.89-6.92-1.93-9.69-2.72c-1.59,3.73-2.15,8.25-4.69,10.2c-3.5,2.7-8.09,4.2-10.71,8.06c-1.43,2.11-2.7,4.79-2.69,7.2
                                        c0.01,1.08,3.36,3.05,5.18,3.03c4.53-0.03,4.93,2.79,5.05,5.96c0.09,2.35-0.15,4.72-0.37,7.06c-0.39,4.24-0.68,8.51-1.45,12.69
                                        c-0.22,1.17-1.84,2.32-3.07,3.03c-5.6,3.22-7.06,5.07-4.41,11.91c1.31,3.39,6.02,5.58,9.41,7.99c1.89,1.34,4.79,1.52,6.27,3.11
                                        c2.3,2.48,4.83,3.54,7.66,2.37c5.77-2.39,11-0.88,16.11,1.59c5.18,2.51,10.01,1.77,15.2-0.15c6.46-2.39,13.1-1.77,19.16,1.48
                                        c1.57,0.84,2.44,3.24,3.29,5.09c1.32,2.86,1.54,6.98,3.69,8.56c3.67,2.69,8.41,5.1,12.83,5.34c7.72,0.42,10.39,2.25,11.19,10.12
                                        c0.21,2.09,2.03,4.07,3.29,5.97c0.82,1.24,2.21,2.12,2.94,3.4C787.59,540.87,789.65,544.93,792.61,550.52z"/>
                                    <path   
                                    d="M950.32,466.79c0-2.74-0.32-4.99,0.1-7.08c0.33-1.63,1.2-3.77,2.49-4.49
                                        c3.79-2.09,8.06-3.32,11.86-5.4c3.18-1.74,6.17-3.98,8.87-6.41c5.49-4.96,10.04-10.88,17.47-13.63c2.5-0.93,5.49-4.55,5.64-7.09
                                        c0.5-8.5,7.48-11.53,12.27-16.4c0.72-0.73,2.21-1.13,3.28-1.03c4.6,0.42,6.15-3.39,6.7-6.21c0.31-1.59-3.12-4.01-5.03-5.88
                                        c-1.24-1.21-3.47-1.84-4.05-3.23c-2.12-5.09-5.6-6.77-10.92-4.41c-3.34,1.48-6.82,1.92-8.19-1.86c-1.07-2.93-0.18-6.3,3.69-7.8
                                        c1.11-0.43,2.52-2.05,2.5-3.09c-0.14-6.28,1.58-11.82,4.63-17.34c1.28-2.33,0.71-6.02,0.04-8.87c-0.19-0.79-3.77-0.76-5.78-1.12
                                        c-3.22-0.58-4.76-2.1-2.47-5.18c1.63-2.19,2.35-4.08,0.97-6.88c-1.7-3.47-0.75-6.39,2.21-9.47c3.5-3.63,5.83-8.39,8.67-12.65
                                        c-0.36-0.41-0.73-0.82-1.09-1.23c-13.02,0.27-26.03,0.61-38.78,3.97c-1.11,0.29-2.32,0.16-3.48,0.27c-1.72,0.17-3.43,0.4-5.15,0.56
                                        c-3.46,0.32-7.01,1.23-10.37,0.77c-6.02-0.81-11.92,3.84-12.71,9.8c-0.67,5.09-2.35,10.07-3.91,15c-1.09,3.43-3.76,6.64-3.88,10.02
                                        c-0.14,4.09,0.07,7.48-4.04,9.83c-0.89,0.51-1.04,2.75-1.09,4.2c-0.26,7.84,1.15,16.07-6.36,21.92c-1.02,0.8-0.57,3.28-1.28,4.74
                                        c-0.63,1.29-1.82,3.06-2.96,3.23c-6.32,0.89-5.68,1.07-8.55,6.96c-2.53,5.21-0.09,9.49,0.64,14.08c0.51,3.17,2.68,5,6.39,3.55
                                        c0.88-0.35,2.48-0.16,3.14,0.44c2.82,2.61,1.36,11.47-2.22,12.57c-5.12,1.58-10.45,2.44-15.64,3.82c-2.2,0.59-4.33,1.58-6.35,2.64
                                        c-2.35,1.24-4.55,2.77-7.59,4.65c1.57,3.47,3.17,8.04,5.62,12.09c1.86,3.06,4.34,6.48,7.4,7.94c6.99,3.32,13.5,6.71,16.83,14.21
                                        c0.31,0.69,1.2,1.11,0.69,0.66c3.41-2.7,6.35-5.62,9.83-7.6c2.75-1.56,4.25-3.09,4.73-6.36c0.31-2.07,2.15-5.2,3.7-5.46
                                        c1.87-0.31,4.23,1.8,6.25,3.08C939.51,464.46,943.82,467.64,950.32,466.79z"/>
                                    <path   
                                    d="M367.96,537.98c0,3.84-0.48,9.58,0.19,15.19c0.38,3.19-0.25,4.88-2.97,5.9
                                        c-1.86,0.7-3.85,1.07-5.77,1.61c-5.52,1.56-11.68,1.91-14.5,8.27c-1.72,3.87-3.63,7.65-5.68,11.93c3.52,0.9,7.87,0.89,10.64,2.97
                                        c3.93,2.96,7.66,3.42,11.8,2.05c4.38-1.45,5.74,0.47,5.64,4.3c-0.17,6.81,3.21,11.14,9.31,13.46c4.06,1.54,6.97,4.93,12.55,4.37
                                        c2.97-0.3,5.35,0.1,7.31-2.31c2.52-3.09,5.35-2.81,8.66-1.44c4.51,1.88,9.07,3.76,13.78,4.99c1.93,0.5,4.45-0.35,6.44-1.18
                                        c3.26-1.36,6.19-3.57,9.5-4.68c1.16-0.39,4.48,1.84,4.39,2.13c-1.56,5.03,2.14,8.7,3.03,13.02c0.75,3.65,2.77,4.64,6.47,3.99
                                        c0.11-1.78-0.11-3.54,0.37-5.08c0.87-2.8,2.16-5.47,3.27-8.2c2.15,1.29,4.23,2.75,6.49,3.81c1.69,0.79,3.78,1.71,5.4,1.33
                                        c1-0.23,1.76-2.72,2.11-4.31c0.96-4.4-1.77-8.97,2.06-13.49c3.89-4.59-4.43-7.76-3.04-12.67c0.18-0.62,0.03-1.35-0.04-2.03
                                        c-0.49-5.04,1.33-9.75-0.34-15.29c-1.86-6.19-0.05-13.5,0.25-20.31c0.11-2.5,1.4-5.32,0.6-7.41c-2.93-7.67-0.11-15.07,0.69-22.46
                                        c0.44-4.03-0.29-6.33-3.21-8.39c-9.46,5.36-16.77,4.14-19.4-1.64c-7.08-1.75-13.17-3.26-19.26-4.77c-0.2-0.58-0.41-1.15-0.61-1.73
                                        c-0.37,0.78-0.57,2.08-1.13,2.27c-2.6,0.84-5.47,2.17-7.9,1.67c-1.18-0.24-2.01-4.07-2.29-6.36c-0.43-3.5,0.02-7.12-0.45-10.62
                                        c-0.27-2.05-1.25-4.23-2.54-5.87c-2.46-3.13-5.3-5.99-8.12-8.81c-0.97-0.97-2.43-2.18-3.58-2.1c-6.2,0.45-12.39,1.16-18.55,2.06
                                        c-1.18,0.17-2.57,1.32-3.21,2.4c-2.59,4.43-1.13,8.21,1.13,12.66c3.72,7.33,5.92,15.42,9.12,23.04c1.14,2.72,2.88,5.6,5.17,7.33
                                        c4.93,3.73,5.77,5.75,0.46,9.01C385.22,530.91,377.29,533.71,367.96,537.98z"/>
                                    <path   
                                    d="M1025.73,756.85c-0.03-0.49-0.07-0.97-0.1-1.46c2.38-1.07,4.78-2.12,7.15-3.21
                                        c0.72-0.34,1.89-0.69,2.01-1.22c1.66-7.42,5.63-14.62,2.82-22.53c-4.73-13.3-9.35-26.57-7.97-41.07c0.16-1.71-0.26-3.86-1.23-5.23
                                        c-8.69-12.26-15.91-25.16-18.74-40.16c-0.4-2.14-0.8-4.65-2.13-6.19c-3.45-4-3.06-8.64-3.64-13.36
                                        c-1.15-9.38,2.58-14.95,11.27-18.14c5.47-2,9.19-5.87,10.45-12.04c0.7-3.41,2.22-6.87,4.18-9.77c3.99-5.92,3.71-11.31-0.92-17.05
                                        c-1.57,0.81-3.27,1.52-4.79,2.5c-4.27,2.76-8.41,5.74-12.72,8.45c-5.09,3.19-17.3,2.95-22.16-0.89c-0.85,2.37-1.41,4.52-2.39,6.47
                                        c-1.6,3.21-2.73,7.38-5.39,9.16c-3.91,2.63-11.81,0.07-14.55-4.21c-2.11-3.3-2.97-7.39-4.57-11.05c-0.75-1.7-1.94-3.2-2.94-4.81
                                        c-1.51,3.02-2.81,6.62-3.34,6.52c-2.62-0.54-5.03-2.06-7.51-3.26c-0.12-0.06-0.17-0.29-0.25-0.44c-1.56-3.06-3.34-3.61-5.73-0.71
                                        c-0.72,0.88-1.86,1.46-2.9,2.02c-7.2,3.91-10.12,10.93-8.61,19.28c0.47,2.58,0.03,5.66-0.97,8.1c-1.45,3.54-1.97,6.54,0.15,10.05
                                        c2,3.31,3.32,7.04,5.13,11c1.58,1.23,4.23,2.6,5.87,4.75c2.16,2.83,4.3,4.26,8.04,4.02c4.05-0.26,7.22,3.11,5.92,6.31
                                        c-1.46,3.61-2.16,6.97-1.86,10.89c0.45,5.88,0.12,11.83,0.12,16.63c10.83,1.55,20.84,3.48,30.94,4.23c6.07,0.45,7.74,3.29,8.24,8.52
                                        c0.65,6.86-2.26,9.29-9.11,7.4c-1.98-0.54-4.12-0.48-6.61-0.74c0.55,5.38,0.56,9.78,4.8,13.11c1.38,1.08,2.01,4.3,1.6,6.25
                                        c-1,4.73,0.11,7.2,4.62,9.71c3.04,1.69,4.82,6.13,6.36,9.69c0.44,1.02-1.98,3.21-2.95,4.93c-0.57,1.02-1.25,2.18-1.24,3.28
                                        c0.03,4-1.68,6.9-4.5,9.7c-3.85,3.82-2.43,9.76,2.49,12.41c1.16,0.63,2.59,1.17,3.28,2.18c2.86,4.14,5.49,8.44,7.96,12.29
                                        c5.87-0.76,11.18-1.45,17.16-2.23c-0.26-2.73-0.74-5.53-0.7-8.32c0.02-1.53,0.53-3.85,1.55-4.37c1.33-0.68,3.47-0.19,5.12,0.29
                                        C1023.66,754.89,1024.65,756.05,1025.73,756.85z"/>
                                    <path 
                                    d="M1012.51,470.55c6.84,0.82,11.13-1.74,13.91-7.12c2.24-4.35,4.45-8.72,6.96-12.91
                                        c2.86-4.78,5.91-9.47,9.14-14c1.13-1.58,2.96-3.52,4.63-3.69c4.44-0.44,6.32-3.15,8.29-7.04c-8.14-1.32-15.83-2.57-23.53-3.82
                                        c0.03-0.48,0.05-0.96,0.08-1.45c1.19-0.08,2.41-0.36,3.58-0.21c5.89,0.74,11.77,1.58,17.64,2.42c5.05,0.73,8.43-1.79,9.01-6.86
                                        c0.15-1.34,0.21-2.72,0.1-4.07c-0.45-5.36,1.78-9.12,6.5-11.59c1.64-0.86,3.44-1.78,4.59-3.15c1.71-2.05,3.34-4.38,4.23-6.87
                                        c1.57-4.42,1.4-9.95,4.06-13.37c4.38-5.63,2.35-11.24,2.3-16.94c-0.01-0.7-1.18-1.78-2-2.01c-3.44-0.98-6.96-1.71-11.29-2.73
                                        c1.27,7.32-0.03,12.99-4.94,16.82c-6.22,4.84-6.87,10.86-5.23,17.78c0.87,3.66-0.81,5.52-4.35,5.85c-4.78,0.45-8.53,2.13-11.45,6.4
                                        c-2.98,4.35-14.14,3.43-14.92-1c-0.92-5.2-2.19-10.84,3.22-14.91c3.59-2.7,4.49-6.39,4.12-10.82c-0.59-6.97-0.25-12.94,5.73-18.98
                                        c6.61-6.67,13.33-12.73,20.99-18.1c3.35-2.35,6.47-6.45,7.5-10.34c1.88-7.13,2.4-14.66,2.96-22.07c0.34-4.54,1.96-8.06,5.1-11.23
                                        c5.75-5.79,8.92-12.92,11.49-20.65c4.57-13.78,9.85-27.33,15.21-40.82c0.96-2.4,3.71-4.13,5.74-6.06c2.15-2.04,5.55-3.24,4.7-8.12
                                        c-2.86,1.84-5.12,3.57-7.62,4.81c-2.35,1.17-5,2.63-7.45,2.51c-4.39-0.23-6.93,1.99-9.47,4.87c-3.66,4.17-7.41,8.26-11.17,12.34
                                        c-1.6,1.74-3.69,3.15-4.9,5.12c-5.76,9.4-11.29,18.94-16.91,28.43c-1.28,2.15-2.12,5.24-4.03,6.23c-6.72,3.47-8.77,9.7-11.5,15.97
                                        c-2.35,5.39-5.78,10.17-13.37,9.44c-5.51-0.53-11.16,0.6-16.76,0.67c-3.5,0.05-5.43,1.52-7.13,4.55c-2.54,4.53-5.81,8.65-8.67,13.01
                                        c-0.69,1.05-1.41,2.38-1.36,3.55c0.16,3.98,0.65,7.95,1.03,12.17c7.42,0.33,8.48,1.47,7.68,10.36c-0.17,1.94-1.3,3.78-1.84,5.71
                                        c-1.07,3.81-1.86,7.71-3.11,11.46c-0.96,2.87-2.36,5.6-3.73,8.31c-0.8,1.57-1.93,2.96-2.91,4.44c1.66,0.04,3.5,0.58,4.94,0.05
                                        c6.47-2.39,11.05-0.69,14.66,5.65c0.52,0.91,1.53,1.87,2.49,2.14c4.4,1.22,6.25,4.68,6.88,8.52c0.49,2.95-6,8.31-9.75,8.82
                                        c-3.04,0.41-12.35,8.59-12.48,11.52c-0.34,7.69-4.52,11.85-11.47,14.26c-5.37,1.87-8.26,6.47-7.67,10.94
                                        c0.59,4.45,4.52,7.4,10.75,8.1c1,0.11,2.17,0.06,2.94,0.57c4.15,2.75,7.02,1.41,10.01-2.14c1.27-1.5,4.44-2.95,5.99-2.38
                                        c7.83,2.9,9.42,9.22,4.69,16.06C1014.28,466.46,1013.63,468.28,1012.51,470.55z"/>
                                    <path   
                                    d="M171.06,697.53c-6.28,0.2-7.61-3.35-8.2-7.75c-0.5-3.74,0.34-6.25,4.49-7.09
                                        c0.82-0.17,1.76-1.73,1.94-2.76c0.92-5.56,4.66-8.27,9.46-10.27c0.84-0.35,1.74-3.01,1.44-3.26c-4.65-4.06-9.22-8.34-14.37-11.7
                                        c-6.52-4.26-15.38-4.45-20.43-11.37c-0.98-1.34-1.94-3.25-1.78-4.78c0.71-6.92,7.59-10.96,14.19-8.77c1.4,0.46,3.02,0.53,4.51,0.41
                                        c7.85-0.6,15.71-1.15,23.51-2.15c1.29-0.16,3-2.34,3.27-3.82c0.39-2.09-0.12-4.41-3.45-4.35c-1.48,0.03-4.08-0.67-4.28-1.5
                                        c-0.89-3.67-1.61-7.54-1.45-11.29c0.36-9.04,8.33-12.22,14.25-16.57c2.61-1.92,3.67-3.5,4.29-6.56c1.49-7.26,4.29-12.24,12.56-9.39
                                        c2.05,0.71,4.38,0.59,6.46,1.22c1.48,0.45,3.35,1.22,4.01,2.41c1.75,3.19,4.47,3.56,7.59,4.13c1.94,0.35,3.61,2.13,5.43,3.22
                                        c1.52,0.91,3.27,2.65,4.57,2.37c1.29-0.27,2.79-2.48,3.08-4.05c1.41-7.61-5.33-10.96-8.88-15.97c-0.32-0.45-1.55-0.57-2.23-0.39
                                        c-5.05,1.33-8.21-0.78-9.3-6.03c-0.35-1.71-1.18-3.4-2.11-4.9c-2.53-4.1-6.32-7.78-7.61-12.22c-2.29-7.83-5.1-14.94-11.29-20.57
                                        c-1.48-1.35-2.89-4.74-2.2-6.16c2.69-5.52-0.56-8.2-3.77-11.73c-2.99-3.28-5.5-7.3-7.08-11.43c-0.71-1.87,1.42-4.78,2.16-7.25
                                        c0.41-1.36,0.6-2.78,0.89-4.18c-0.46-0.39-0.92-0.78-1.39-1.17c-1.76,1.36-4.2,2.35-5.14,4.14c-2.52,4.78-4.49,9.86-6.51,14.89
                                        c-5.07,12.58-9.92,25.25-15.14,37.77c-2.1,5.04-5.52,8.87-11.77,9c-2.74,0.06-9.02,8.29-7.82,10.71c3.48,7.01-1.75,10.1-5.28,14.29
                                        c-1.99,2.36-3.66,5.21-4.67,8.12c-1.73,4.99-2.94,9.69-8.54,12.42c-3.02,1.47-4.8,5.53-7.07,8.47c-1.03,1.33-1.65,3.1-2.9,4.12
                                        c-3.93,3.2-4.14,6.66-1.46,10.69c10.56,15.88,16.07,33.49,19.23,52.21c1.47,8.71,5.36,17.02,8.4,25.43
                                        c0.58,1.61,2.03,3.16,3.48,4.14c3.6,2.43,7.21,5.23,11.25,6.52c4.11,1.32,8.81,1.58,13.1,1.05
                                        C169.58,705.71,170.09,700.84,171.06,697.53z"/>
                                    <path  
                                    d="M845.9,251.94c-2.99-2.18-7.06-3.82-7-5.33c0.29-8.42-4.01-8.83-10.24-6.97
                                        c-0.47,0.14-0.93,0.26-1.31,0.37c-0.76,3.43-2.97,7.29-1.96,9.92c3.42,8.9-1.98,15.39-6.53,20.48c-4.17,4.66-6.42,9.55-8.62,14.92
                                        c-0.67,1.64-1.41,3.74-2.76,4.53c-8.61,5.05-17.72,8.87-27.76,10.19c-2.27,0.3-6.06,1.04-6.34,2.24c-0.89,3.75-1.77,8.49-0.21,11.62
                                        c3.08,6.17,3.67,7.49-2.64,10.25c-2.48,1.08-5.97-0.14-9-0.34c-1.4-0.09-3.35-0.92-4.1-0.29c-4.46,3.75-9.29,5.17-15.03,3.86
                                        c-1.34-0.31-2.89,0.31-6.01,0.73c2.42,3.93,3.6,8.44,6.26,9.62c4.88,2.17,5.02,5.56,4.56,9.39c-0.49,4.14,1.54,5.2,5,5.97
                                        c2.06,0.46,4.63,1.68,5.62,3.36c2.98,5.07,4.94,10.75,7.99,15.77c3.45,5.68,8.72,9.46,15.37,10.8c3.27,0.66,6.64,0.87,10.02,1.29
                                        c1.37-1.2,3.2-4.16,5.11-4.22c4.19-0.12,6.49-2.14,8.73-5.08c3.08-4.04,6.36-7.93,9.48-11.95c3.58-4.62,7.55-8.02,14.08-7.02
                                        c3.48,0.53,7.16-0.26,10.67,0.16c11.08,1.35,27.79-12.4,29.71-23.45c0.82-4.71,2.69-8.63,7.45-10.77c1.28-0.58,2.6-2.21,2.92-3.59
                                        c1.06-4.66,1.31-9.51,2.59-14.09c0.85-3.04,0.64-4.54-2.4-5.91c-6.02-2.7-11.78-5.98-17.78-8.72c-6.5-2.97-8.91-7.56-7.52-14.67
                                        c0.5-2.58,0.35-6.56-1.23-8.03C848.09,262.44,844.89,257.58,845.9,251.94z"/>
                                    <path   
                                    d="M485.92,576.11c1.98-2.68,2.9-5.26,4.66-6.1c9.57-4.56,18.09-10.29,24.52-18.91
                                        c1.17-1.57,2.91-2.96,4.69-3.79c2.93-1.36,2.82-3.31,2.05-5.83c-1.96-6.39-3.4-12.97-5.86-19.15c-1.71-4.28-4.72-8.05-7.25-11.98
                                        c-0.61-0.95-2.26-1.62-2.32-2.49c-0.49-8.17-1.36-15.96,3.58-23.88c5.45-8.73,13.13-18.5,5.68-29.48
                                        c-3.24-4.78-8.07-8.63-11.07-13.89c-1.1-1.94-3.61-3.04-4.92-4.91c-2.6-3.72-4.63-7.84-7.23-11.56c-3.47-4.97-7.18-4.59-9.53,1.06
                                        c-1.17,2.81-3.2,4.13-5.46,2.88c-6.04-3.36-11.81-7.23-17.54-11.09c-0.69-0.47-0.6-2.17-0.79-3.31c-0.5-2.96-0.97-5.92-1.5-9.24
                                        c-5.37,0.97-10.29,1.43-13.15-4.03c-4.58,2.62-8.72,5-12.93,7.41c-0.41,1.2-0.54,3.11-1.55,4.2c-2.63,2.83-2.33,4.91,0.09,7.86
                                        c2.26,2.76,3.37,6.26,0.7,9.85c-0.51,0.69-0.19,3.04,0.44,3.39c4.62,2.56,4.62,6.51,3.98,10.94c-0.19,1.28,0.59,2.7,1,4.38
                                        c1.92-0.82,3.09-1.51,4.36-1.83c6.19-1.57,10.77,0.69,9.88,5.08c-0.95,4.7-3.76,9.21-0.07,13.92c0.22,0.28-0.48,1.18-0.59,1.83
                                        c-1.31,7.9-2.62,15.8-3.82,23.72c-0.1,0.68,0.38,1.8,0.95,2.17c4.01,2.62,2.79,5.13,0.76,8.54c-0.84,1.4,0.73,6.2,1.54,6.29
                                        c3.35,0.36,6.83-0.45,10.25-0.92c2.03-0.28,5.18-1.69,5.83-0.92c2.06,2.45,4.87,6.03,4.37,8.57c-1.67,8.48-3.56,16.74-0.87,25.32
                                        c0.29,0.91-0.03,2-0.03,3.01c-0.01,5.11,0,10.22,0,15.82c4.61-1.78,7.31-0.6,7.38,3.65C476.27,569.98,482.38,572.12,485.92,576.11z"
                                        />
                                    <path  
                                    d="M183.19,615.28c3.45,2.25,6.74,3.19,7.68,5.22c3,6.45,0.5,9.9-6.64,10.34
                                        c-0.85,0.05-1.76-0.1-2.52,0.18c-8.91,3.26-17.82,1.27-26.81,0.54c-2.51-0.2-5.55,2.38-7.78,4.33c-0.97,0.86-1.32,4.03-0.54,4.97
                                        c2.14,2.56,4.74,5.72,7.69,6.44c10.41,2.53,18.53,8.35,26.08,15.5c4.18,3.96,3.62,7.14-1.24,9.78c-2.82,1.54-4.92,4.54-7.08,7.1
                                        c-2.08,2.46-3.83,5.2-6.54,8.95c0.32,5.9,0.32,5.9,6.89,5.52c0,4.14,0.85,8.55-0.36,12.29c-0.6,1.87-4.97,2.52-7.66,3.74
                                        c0.02,7.4,2.75,9.72,11.76,9.93c2.19-6.16,4.52-7.4,11.59-5.23c4.55,1.39,3.28,5.19,2.65,8.52c-0.57,3,0.76,4.46,3.75,4.69
                                        c1.68,0.13,3.35,0.48,5.02,0.73c3.65,0.54,5.19-1.25,5.52-4.59c-6.33-2.6-8.77-7.58-4.94-11c4.38-3.91,5.98-11.01,13.22-11.84
                                        c0.86-0.1,1.64-1.91,2.24-3.05c2.57-4.85,4.95-9.81,7.65-14.58c0.94-1.66,3.36-2.7,3.83-4.38c1.17-4.15,2.16-8.5,2.19-12.77
                                        c0.04-5.99,3.74-7.66,8.41-9.01c6.31-1.83,9.94-4.2,10.07-9.6c0.05-2.11,1.32-4.87,2.97-6.14c4.07-3.15,6.07-6.89,7.15-11.94
                                        c1.33-6.22-3.51-16.88-9.58-18.74c-0.77-0.23-1.69-0.36-2.45-0.17c-4.08,1.04-5.32-1.3-5.31-4.64c0.01-5.13,0.33-10.25,0.51-15.23
                                        c-2.03-0.63-6.34-0.73-7.15-2.46c-1.85-3.97-4.47-4.51-8.04-4.55c-1.09-0.01-2.84-0.39-3.16-1.11c-2.39-5.37-7.64-4.69-11.96-6.19
                                        c-5.39-1.88-7.51,1.01-9.06,5.2c-0.46,1.25-0.72,2.64-0.75,3.98c-0.06,3.19-1.14,4.97-4.48,6.35
                                        C183.67,597.43,180.3,604.68,183.19,615.28z"/>
                                    <path 
                                    d="M243.68,727.51c6.37,2.94,12.7,4.71,17.5,8.45c3.48,2.71,6.52,5.42,10.91,6.42
                                        c5.36,1.22,6.96-0.32,6.61-5.95c-0.16-2.54,0.53-5.16,1.03-7.71c0.36-1.81,1.67-3.63,1.49-5.32c-0.79-7.31,2.07-13.66,4.61-20.14
                                        c0.36-0.91,0.69-2.1,0.4-2.95c-1.87-5.51-2.21-11.65-7.28-15.87c-1.23-1.03-1.02-3.94-1.22-6.01c-0.2-2,0.64-4.46-0.25-5.96
                                        c-3.8-6.46-4.13-6.74,1.06-11.27c3.26-2.85,2.48-9.1,8.79-9.43c6.61-0.34,10.99-5.69,12.22-12.32c0.48-2.58,2.59-6.21,4.7-6.82
                                        c3.62-1.05,4.49-2.99,3.99-5.81c-0.83-4.7-2.49-9.26-3.26-13.97c-0.68-4.19-2.27-6.95-6.8-7.37c-3.4-0.32-4.31-1.95-4.8-5.61
                                        c-0.42-3.15-3.34-7.16-6.21-8.44c-2.91-1.3-4.56-2.64-6.05-5.27c-2.05-3.61-4.34-7.23-7.2-10.2c-3.53-3.67-7.28-6.85-4.07-12.55
                                        c-4.33-1.64-6.11,1.25-8.34,3.54c-4.07,4.2-6.04,3.6-8.26-1.8c-1.02-2.48-2.75-4.68-4.86-8.16c-2.38,9.78-9.58,5.72-16.1,7.49
                                        c1.83,1.13,2.81,1.53,3.52,2.2c7.8,7.39,11.05,16.09,8.21,26.86c-1.23,4.67-1.62,9.56-2.46,14.73c7.16-0.3,11.54,2.21,14.44,7.37
                                        c0.49,0.87,1.13,1.87,1.97,2.28c3.51,1.71,3.63,4.58,2.96,7.78c-0.59,2.79-1.09,5.62-1.97,8.33c-0.65,2.03-1.33,4.6-2.87,5.7
                                        c-4.32,3.09-6.93,6.75-7.1,12.19c-0.09,2.99-0.87,5.42-4.92,5.71c-2.83,0.2-5.66,1.66-8.28,2.97c-1.54,0.78-3.41,2.15-3.87,3.64
                                        c-2.33,7.57-1.01,16.4-7.99,22.36c-0.39,0.33-0.09,1.37-0.38,1.95c-0.65,1.33-1.16,3.08-2.27,3.76c-3.32,2.02-4.55,4.93-2.85,8.08
                                        c1.88,3.48,5.19,3.23,8.38,1.3c2.58-1.56,4.65-1.26,6.55,1.35c1.04,1.43,3.12,3.59,4.14,3.3c5.62-1.6,5.05,2.71,5.36,5.49
                                        C243.43,717.18,243.45,722.6,243.68,727.51z"/>
                                    <path  
                                    d="M742.01,210.63c-0.21,3.6-0.55,6.97-0.57,10.34c-0.06,8.2-3.28,10.72-11.68,8.95
                                        c-6.38-1.34-10.7,1.78-11.83,8.12c-0.28,1.59-2,2.88-2.7,4.47c-0.69,1.58-1.41,3.43-1.17,5.02c0.14,0.9,2.12,1.99,3.38,2.16
                                        c2.84,0.38,5.78-0.04,8.61,0.38c2.27,0.33,5.13,0.77,6.52,2.28c3.62,3.93,6.57,8.46,10.03,13.09c4.02-3.23,8.99-6.53,14.16,0.07
                                        c0.44,0.56,1.55,0.62,2.36,0.86c8.27,2.45,11.01,7.09,8.92,15.06c-0.16,0.59-0.02,1.27-0.02,2.54c3.84-1.75,7.15-3.79,10.75-4.71
                                        c2.3-0.59,5.06,0.33,7.51,0.98c0.42,0.11,0.55,2.3,0.39,3.47c-0.5,3.62-1.2,7.21-1.79,10.64c7.79,2.68,12.31-2.9,17.83-4.9
                                        c2.09-0.76,3.77-3.46,5-5.64c2.48-4.4,3.41-10.6,7.04-13.21c4.64-3.35,5.21-8.37,8.15-12.24c1.08-1.43-0.12-4.47,0.03-6.75
                                        c0.3-4.57,0.69-9.15,1.39-13.68c0.08-0.52,2.3-0.89,3.56-1.03c4.75-0.52,9.52-0.91,14.64-1.38c-0.44-1.06-0.62-1.9-1.08-2.54
                                        c-3.84-5.37-5.58-10.84-3.18-17.61c1.26-3.55,0.35-7.28-3.61-10.14c-4.46-3.23-7.9-7.84-12.06-11.54c-1.83-1.63-4.29-3.36-6.58-3.53
                                        c-10.73-0.82-21.5-1.16-32.26-1.6c-5.94-0.24-7.82,3.98-6.43,9.66c1.25,5.15,1.21,11.18-0.29,16.22c-0.9,3.05-3.21,5.77-3.35,9.48
                                        c-0.12,3.43-4.24,6.38-6.28,4.73c-2.89-2.35-6.14-5.18-7.35-8.49C756.74,211.07,750.83,207.7,742.01,210.63z"/>
                                    <path  
                                    d="M783.61,280.58c-3.95,1.57-7.85,3.34-11.9,4.63c-1.8,0.58-4.19,0.74-5.77-0.01
                                        c-0.74-0.35-0.8-3.6-0.16-5.1c1.4-3.27,1.48-5.94-1.8-7.85c-3.3-1.92-7.29-3.01-10.01-5.51c-3.38-3.11-5.73-2.09-8.76,0.01
                                        c-5.56,3.88-5.86,3.69-7.63-3.15c-1.84-7.1-6.67-10.98-13.9-11.23c-3.22-0.11-6.45-0.53-9.66-0.39c-2.48,0.11-6,0.03-7.21,1.52
                                        c-4.23,5.25-9.2,3.83-14.35,2.83c-3.71-0.72-14.18,4.35-14.97,7.65c-1.49,6.19-2.87,12.45-3.6,18.76
                                        c-0.72,6.19,0.51,12.78-1.12,18.65c-1.49,5.38-0.95,9.64,1.88,13.82c4.34,6.41,7.79,12.95,7.99,21c0.08,3.29,1.13,6.83,2.69,9.75
                                        c1.78,3.34,4.07,6.94,7.11,8.95c4.68,3.09,5.59,4.33,3.39,9.69c-0.86,2.09-1.58,4.48-1.45,6.67c0.2,3.35,2.2,4.98,5.59,3.13
                                        c2.15-1.17,4.15-2.63,5.19-3.3c-0.58-4.14-2.42-8.06-1.17-10.07c3.66-5.91,2.84-11.63,1.86-17.81c-0.46-2.89-0.21-6.25,0.86-8.95
                                        c1.78-4.51,4.27-8.78,6.86-12.9c1.81-2.89,4.22-4.11,7.59-1.33c1.61,1.33,4.18,1.54,6.34,2.14c1.6,0.45,3.48,0.33,4.84,1.14
                                        c5.53,3.28,11.84,2.25,17.09,1.15c5.84-1.23,10.77-6.27,17.46-1.98c0.91,0.59,3.81-1.53,5.44-2.82c0.29-0.23-1.61-2.59-1.67-4
                                        c-0.21-4.88-0.86-9.96,0.22-14.6c0.8-3.44,4.34-5.44,8.6-3.94c0.76,0.27,1.86-0.4,2.43-0.54
                                        C782.46,291.51,782.95,286.83,783.61,280.58z"/>
                                    <path  
                                    d="M442.31,370.35c2.33,4.04,2.56,12.89-0.06,13.97c-6.6,2.72-4.69,5.98-1.61,9.95
                                        c0.76,0.98,0.87,2.47,1.28,3.73c0.3-0.33,0.61-0.66,0.91-0.99c1.85,1.25,3.63,2.64,5.58,3.7c1.53,0.83,3.63,2.19,4.87,1.72
                                        c6.12-2.33,7.96,0.25,7.83,5.91c-0.03,1.15,0.69,2.34,0.62,3.48c-0.32,4.69,2.23,6.47,6.45,6.48c2.58,0,3.78,0.68,4.34,3.62
                                        c0.3,1.55,3.1,2.96,5.06,3.69c0.69,0.26,2.63-1.49,3.28-2.71c2.93-5.47,8.14-6.39,12.21-1.78c1.54,1.74,2.4,4.07,3.86,5.89
                                        c3.21,3.99,6.62,7.83,9.95,11.73c0.86,1.01,2.46,2.01,2.47,3.02c0.06,6.13,5.34,7,8.86,9.86c1.77-3.7,2.76-7.54,5.07-10.25
                                        c3.67-4.31,4.1-8.89,3.79-14.12c-0.11-1.79,1.13-4.38,2.59-5.42c4.51-3.21,9.22-7.1,15.23-2.73c0.18,0.13,0.63-0.12,0.96-0.2
                                        c4.38-11.28,4.37-11.28-1.52-22.34c-0.47-0.89-0.99-1.83-1.14-2.8c-1.3-8.38-3.01-16.44-12.78-19.23c-0.73-0.21-1.22-1.16-1.92-1.6
                                        c-1.93-1.23-3.88-2.46-5.9-3.55c-2.04-1.1-4.32-1.79-6.23-3.06c-4.06-2.71-8.05-5.55-11.87-8.59c-1.76-1.4-3.94-3.21-4.35-5.17
                                        c-1.01-4.78-4.19-7.12-8-9.2c-3.25-1.78-6.74-3.28-9.61-5.56c-4.25-3.37-5.49-3.1-7.27,1.98c-1.66,4.73-3.08,10.04-10.11,9.15
                                        c-0.78-0.1-1.99,1.03-2.57,1.89c-1.65,2.46-3.08,5.07-4.73,7.87c0.94,0.87,2.18,1.77,3.09,2.93c0.93,1.19,1.7,2.56,2.23,3.97
                                        c1.24,3.32-1.34,7.19-4.55,6.53c-4.45-0.92-10.09,1.35-12.44-5.13C444.52,368.42,443.53,369.28,442.31,370.35z"/>
                                    <path  
                                     d="M934.04,465.8c-1.51-1.53-2.76-2.8-4.01-4.06c-0.89,1.61-2.22,3.13-2.59,4.86
                                        c-1.75,8.26-1.8,16.22,4.16,23.27c1.3,1.54,1.86,4.37,1.56,6.42c-0.43,2.94-1.97,5.71-2.9,8.2c3.36,2.37,5.64,4.52,8.33,5.77
                                        c7.15,3.31,14.83,5.61,21.62,9.48c6.34,3.62,14.2,5.22,18.23,12.44c2.04,3.65,5.41,5.26,9.72,5.41c1.87,0.06,3.83,1.19,5.51,2.23
                                        c3.94,2.43,7.01,1.97,9.37-1.94c4.15-6.86,7.59-13.88,7.08-22.36c-0.26-4.35,0.68-8.77,0.99-13.16c0.29-4.14,1.9-7.51,5.46-9.79
                                        c3.26-2.09,5-4.96,4.58-8.91c-0.53-4.98,1.92-7.2,6.69-7.29c4.58-0.09,7.11-2.38,7.81-6.93c0.31-2,1.01-3.94,1.62-5.88
                                        c1.81-5.8,3.59-11.37,8.32-15.95c3.12-3.01,4.48-7.84,6.63-11.85c-0.48-0.46-0.97-0.93-1.45-1.39c-1.94,0.94-4.7,1.36-5.68,2.9
                                        c-5.78,9.11-10.96,18.59-16.74,27.7c-1.84,2.9-4.47,5.87-7.46,7.32c-2.66,1.29-6.32,0.51-9.53,0.64c0.5-3.09,0.89-6.2,1.55-9.25
                                        c0.24-1.09,1.67-2.04,1.66-3.04c-0.02-1.83-0.35-3.84-1.2-5.43c-0.83-1.56-2.47-2.7-3.9-4.17c-0.91,0.53-2.06,0.82-2.57,1.55
                                        c-3.55,5.15-7.77,6.75-13.29,2.98c-0.37-0.25-0.97-0.17-1.47-0.23c-7.09-0.8-13.09-3.04-14.35-10.32
                                        c-6.33,3.78-12.45,8.27-19.25,11.15c-3.84,1.63-5.84,2.62-5.5,7.11c0.53,7.09-0.23,7.66-7.41,6.16
                                        C941.67,468.58,937.84,467.01,934.04,465.8z"/>
                                    <path 
                                    d="M996.6,543.71c-4.55-1.61-8.18-2.7-11.65-4.18c-2.52-1.07-5.11-2.32-7.15-4.08
                                        c-2.39-2.07-3.77-6.02-6.39-7c-11.66-4.34-20.78-14.12-33.72-15.61c-1.06-0.12-2.12-1.02-3.02-1.75c-5.1-4.16-8.01-4.09-12.56,0.79
                                        c-4.04,4.34-8.11,8.69-11.67,13.42c-2.02,2.69-2.94,6.19-4.53,9.23c-0.76,1.46-1.67,3.08-2.97,3.96c-3.1,2.09-3.73,4.92-3.62,8.34
                                        c0.14,4.5,0.04,9.01,0.04,13.67c3.59,1.09,6.94,2.94,10.31,2.95c4.96,0.03,8.74,0.68,9.41,6.65c4.86-1.08,8.99-1.86,13.23,1.57
                                        c2.49,2.01,6.47,2.57,9.16-1.75c1.71-2.76,6.24-1.67,8.56,1.35c1.08,1.41,2.49,2.56,4.27,4.35c1.35-7.97,1.42-8.12,7.14-6.43
                                        c1.24,0.36,2.59,1.88,3,3.15c0.88,2.72,0.94,5.7,1.78,8.43c1.62,5.23,6.36,8.41,11.43,8.46c4.42,0.04,5.45-3.43,6.11-6.31
                                        c0.98-4.28,1.84-8.16,6.17-10.43c0.8-0.42,1.1-2.38,1.1-3.63C991.04,560.42,990.08,551.84,996.6,543.71z"/>
                                    <path  
                                    d="M463.23,733.24c0.52-0.34,1.04-0.69,1.56-1.03c0-7.85,0.38-15.72-0.1-23.54
                                        c-0.52-8.26,4.18-13.82,12.48-13.99c1.51-0.03,3.31-0.88,4.43-1.93c4.83-4.57,4.63-10.07,2.71-15.95c-2.2-6.73-0.17-10.7,6.38-12.65
                                        c5.43-1.61,8.96-4.95,10.73-10.44c-6.24-4.27-9.91-9.64-7.9-17.69c-4.18,0-8.34,0.61-12.22-0.17c-4.05-0.81-7.79-3.06-11.74-4.49
                                        c-3.84-1.39-6.57,0.89-6.65,5.35c-0.26,14.68-0.84,15.31-15.05,16.84c-2.19,0.23-4.42,1.02-6.54,0.76
                                        c-4.47-0.55-5.42,1.88-6.06,5.53c-0.53,3.04-1.79,5.98-2.86,8.91c-1.6,4.4-4.55,8.94-0.49,13.26c4.01,4.26,2.67,9.34,0.97,13.01
                                        c-3.11,6.68-2.84,13.54-3.83,20.38c-0.58,3.97-2.39,7.75-3.59,11.64c-0.44,1.42-0.69,2.91-1.03,4.36c1.67-0.32,3.64-0.18,4.94-1.05
                                        c4.46-2.98,8.59-6.45,13.07-9.39c0.91-0.6,3.12-0.21,4.16,0.52c4,2.82,7.71,6.04,11.64,8.96
                                        C459.76,731.57,461.56,732.32,463.23,733.24z"/>
                                    
                                    </svg>
    
    
    
    </div>
</template>

<script>
export default {
    props:['nombre']
}
</script>

<style>
    .boardColor{
        fill:#853e96;
    }
    .color1{
        fill:#520c9c;
    }
    .color2{
        fill:#632d9c;
    }
    .icono{
        width:200px;
        height:200px;
    }
</style>