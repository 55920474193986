<template>
    <BasicLayout/>
</template>

<script>
import BasicLayout from '../src/layouts/BasicLayout'
export default {

  components:{
    BasicLayout
  }
  
}
</script>

<style>

  body{
    width:100vw;
    height:100vh;
    padding:0;
    margin:0;
    font-family: 'Montserrat', sans-serif;
  }

</style>
