<template>
  <div class="home">
    <!-- Header principal- -->
      <div class="home-header">
        <div class="img_header">
            <div class="contenedor_img_header">
                <div class="text_header">
                  <h2>Plataforma de Datos de Feminicidios</h2>
                  <h3>en el Estado de Morelos, 2000 - 2021</h3>
                </div>
            </div>
        </div>
      </div>
      <!-- Contenedor de los enlacess -->
      <div class="home-hero-enlaces">
        <div class="hero-enlace">
          <ElementoSVG nombre="boardIcon"/>
          <router-link to="consulta">Consulta de datos</router-link>
        </div>
        <div class="hero-enlace">
          <ElementoSVG nombre="mapaMorelos"/>
          <router-link to="consulta">Mapa Interactivo</router-link>
        </div>
        <div class="hero-enlace">
          <ElementoSVG nombre="chartIcon"/>
          <router-link to="consulta">Gráficas</router-link>
        </div>
      </div>
      <!-- banner con información necesaria -->
      <div class="home-datos-animados">
        <p>Datos ánimados</p>
      </div>

      <!-- Contenedor información sobre el proyecto -->
      <div class="home-informacion">
        <p>El Instituto de la Mujer para el Estado de Morelos y la Fiscalía General del Estado en colaboración directa con la Fiscalía Especializada para la investigación y persecución del delito de Feminicidio, como una acción afirmativa de transparencia proactiva, damos el paso de coordinar esfuerzos para transparentar a la ciudadanía el panorama de feminicidios en nuestra entidad. La información permite también, desarrollar políticas públicas eficaces para la <b>prevención, atención, sanción y erradicación de las violencias contra las mujeres.</b></p>
      </div>

      <!-- Texto hero -->
      <div class="home-datos-animados texto-hero">
        <p class="texto-principal">Vida</p>
        <p class="texto-secundario">sin violencia contra las Mujeres</p>
      </div>

      <!-- Definiciones -->
      <div class="home-body-texto">
        <div class="body-definicion">
          <h2>¿Qué es un feminicidio?</h2>
          <p>La muerte violenta de las mujeres por razones de género, tipificada en nuestro sistema penal como feminicidio, es la forma más extrema de violencia contra la mujer y una de las manifestaciones más graves de la discriminación.</p>
          <p>De acuerdo con el <a href="http://marcojuridico.morelos.gob.mx/archivos/codigos/pdf/CPENALEM.pdf" target="_blank" rel="noopener noreferrer">Artículo 213 Quintus del Código Penal del Estado de Morelos</a> , Comete el delito de feminicidio quien prive de la vida a una mujer por razones de género. Se considera que existen razones de género cuando se acredite cualquiera de las siguientes circunstancias: </p>
          <ol type="I">
            <li>La víctima presente signos de violencia sexual de cualquier tipo;</li>
            <li>A la víctima se haya infligido lesiones infamantes, degradantes o mutilaciones, prevía o posterior a la privación de la vida o actos de necrofília</li>
            <li>Existan antecedentes o datos de cualquier tipo de violencia en el ámbito familiar, laboral o escolar, del sujeto activo en contra de la víctima;</li>
            <li>Haya existido entre el activo y la víctima una relación de parentesco por consanguinidad o afinidad, de matrimonio, concubinato, noviazgo, cualquier otra relación de hecho, sentimental, afectiva o de confianza;</li>
            <li>Hay o se haya dado, entre el activo y la víctima una relación laboral, docente, o cualquier otro que implique confianza, subordinación o superioridad;</li>
            <li>Existan datos que establexcan que hubo amenazas relacionadas con el hecho delictuoso, acoso o lesiones del sujeto activo en contra de la víctima;</li>
            <li>La víctima haya sido incomunicada, cualquiera que sea el tiempo previo a la privación de la vida, y</li>
            <li>El cuerpo de la víctima sea expuesto o arrojado en un lugar público</li>
          </ol>
        </div>
        <div class="body-definicion">
          <h2>Alerta de violencia de género</h2>
          <p>La alerta de violencia de género contra las mujeres (AVGM) es un mecanismo de protección de los derechos humanos de las mujeres único en el mundo, establecido en la Ley General de Acceso de las Mujeres a una Vida Libre de Violencia y su trámite para emitirla o concederla lo describe el Reglamento de dicha Ley. </p>
          <p>Consiste en un conjunto de acciones gubernamentales de emergencia para enfrentar y erradicar la violencia femenicida y/o la existencia de un agravio comparado que impida el ejercicio pleno de los derechos humanos de las mujeres, en un territorio determinado (municipio o entidad federativa); la violencia contra las mujeres la pueden ejercer los individuos o la propia comunidad. <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LGAMVLV_010621.pdf" target="_blank" rel="noopener noreferrer">(Artículo 22 de la Ley de Acceso).</a></p>
          <p>En Morelos la alerta de violencia de género fue declarada en agosto del 2015 por el alto índice de feminicidios. Nuestro mapa reporta el número de feminicidios que dieron origen a la Alerta de Violencia de Género contra las mujeres en nuestra entidad, así como el registro de feminicidios hasta junio del 2020. La información de la plataforma de datos de feminicidios del estado de Morelos se actualizará de manera mensual.</p>
        </div>
        <div class="body-definicion">
          <h2>¿Cuándo se tipificó el delito en Morelos</h2>
          <p>Posterior a la tramitación del Caso contra el Estado mexicano, Campo Algodonero ante la Corte Interamenricana de Derechos Humanos, la armonización legislativa nacional y local se suscitaron escalonadamente; en el Estado de Morelos la reforma fue publicada en el Periódico Oficial "Tierra y Libertad", No. 4916 de fecha 2011/09/01. Hasta la publicación de esta reforma, dichos hechos delictivos eran tipificados como homicidios dolosos.</p>
          <a href="http://periodico.morelos.gob.mx/periodicos/2011/4916.pdf" target="_blank">Publicación en el Periódico Oficial "Tierra y Libertad"</a>

          <h2>¿Cuál es el periodo de tiempo tomado en cuenta para la Declaración de AVGM</h2>
          <p>La Secretaría de Gobernación, a través de la Comisión Nacional para Prevenir y Erradicar la Violencia contra las Mujeres (Conavim), emitió el 10 de agosto del 2015 la delacración de Alerta de Violencia de Género contra las Mujeres (AVGM) en los siguientes municipios del Estado de Morelos: <b>Cuautla, Cuernavaca, Emiliano Zapata, Jiutepec, Puente de Ixtla, Temixco, Xochitepec y Yautepec</b></p>

          <p>La determinación adoptada se motivó en las conclusiones a las que llegó el grupo de trabajo encargado de estudiar y analizar la situación de los derechos humanos de la mujer en esta entidad en el periodo comprometido entre enero de 2000 y junio de 2013</p>
        </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '../layouts/BasicLayout';
import ElementoSVG from '../components/ElementosSVG.vue'

import{ onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'Home',
  components:{
    BasicLayout,
    ElementoSVG
  },
 
}
</script>

<style scoped>

.home{
  width:100%;
  
}

/* HEADER */

      .img_header{
        width:100%;
        height:400px;
      }
      .contenedor_img_header{
        width:100%;
        height:400px;
        position:relative;
      }
      .contenedor_img_header::before{
        content:"";
        position:absolute;
        top:0; left:0;
        width:100%; height:400px;
        background-image: url("../assets/img/violenciaGenero.jpg");
        background-position:center;
        filter:grayscale(100%) brightness(20%);  
      }
      .text_header{
        width:100%;
        height: 400px;
        position:relative;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        font-family: 'Montserrat', sans-serif;
      }
      .text_header h2{
        font-size:40px;
        color:#fff;
        text-align:center;
        font-weight: bold;
        margin:0;
      }
      .text_header h3{
        font-size:28px;
        color:#fff;
        text-align:center;
        margin:0;
      }


/* hero enlaces */

    .home-hero-enlaces{
      width:100%;
      height:300px;
      display:flex; align-items:center; justify-content:center;
      background-color:#fff;
    }
    .hero-enlace{
      width:33%;
      display:flex; align-items:center; justify-content:center;
      flex-direction:column;
    }


/* Datos animados */

.home-datos-animados{
  width:100%;
  height:300px;
  background-color: #4c2775;
}
.texto-hero{
  display:flex; align-items:center; justify-content:center;
}

.texto-principal{
  font-size:100px;
  font-weight: bold;
  color:#fff;
  text-transform:uppercase;
  margin-right:15px;
}
.texto-secundario{
  font-size:22px;
  color:#fff;
  width:280px;
  text-transform:uppercase;
}

/* Información texto */
.home-informacion{
  width:60%;
  margin:70px auto;
  text-align:justify;
}
.home-informacion p{
  font-size:18px;
}

.home-body-texto{
  width:80%;
  margin:10px auto;
  display:flex; flex-wrap:wrap;
}
.body-definicion{
  width:45%;
  margin:0 auto;
  padding:10px 12px;
}
.body-definicion h2{
  text-align:center;
}
.body-definicion p{
  text-align:justify;
}
.body-definicion:last-child{
  width:100%;
  text-align:center;
  background-color:#f2f2f2;
}
.body-definicion:last-child p{
  width:90%;
  margin:10px auto;
}
.body-definicion:last-child h2{
  width:60%;
  margin:30px auto;
}
</style>
