import { createStore } from 'vuex'

export default createStore({
  state: {
    dataFem:[],
    totalRegistros:null
   
  },
  mutations: {
    setFemData( state, payload ){
      state.dataFem = payload.dataFem;
      state.totalRegistros = payload.total
    }
  },
  actions: {
    async getFemData({commit}, params) {
      const url = "../json/datas.json";
      try {
        const response = await fetch(url);
        const result = await response.json();

        if(!params?.value) {
          const numeroData = {
            total: result.length,
            dataFem: result
          };
          commit("setFemData", numeroData);
          
        }else{

         const filter = result.filter( item => 
          item.periodo === params.value || item.municipio === params.value || item.civil === params.value || item.estudios === params.value || item.ocupacion === params.value || item.edad === params.value )

          const totalFilter = filter.length;
          

          const numeroData = {
            total: totalFilter,
            dataFem: filter
          }
         commit("setFemData",numeroData)
        }
      } catch (error) {
        console.log(error);
      }
    },

  },
  modules: {
  }
})
